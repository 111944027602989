import React, { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import { Link } from 'react-router-dom';
import {
  Typography,
  CircularProgress,
  Paper,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/AddBox';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { LinkForward } from '../../components/common/LinkForward';
import { SideMenuLayout } from '../../layouts';
import { withAuth, AuthedComponentProps } from '../../auth/Authenticated';
import {
  EmployeeApi, EmployeeResponse,
  SurveyResultApi, SurveyResultResponse,
  CounselingApi, CounselingResponse,
} from '../../generated';
import { usePrivateApi } from '../../api/useApi';

const Content = ({ session }: AuthedComponentProps) => {
  const { match, history } = useReactRouter<{ id: string }>();
  const id = Number(match.params.id);
  const { api: employeeApi } = usePrivateApi(EmployeeApi);
  const { api: surveyApi } = usePrivateApi(SurveyResultApi);
  const { api: counselingApi } = usePrivateApi(CounselingApi);
  const [visible, setVisible] = useState(false);
  const [employee, setEmployee] = useState<EmployeeResponse>();
  const [surveyResults, setSurveyResults] = useState<SurveyResultResponse[]>();
  const [counselings, setCounselings] = useState<CounselingResponse[]>();
  const statusJp: { [key: string]: string } = { reservation: '予約済', cancel: 'キャンセル済', counseled: '実施済' };

  const styles = makeStyles(theme => ({
    cell: {
      overflowWrap: 'break-word',
      maxWidth: '200px',
    },
    button: {
      margin: theme.spacing(1),
    },
  }))();

  function counselingInfos(s: SurveyResultResponse): [number, string][] {
    if (counselings) {
      const resultCounselings = counselings
        .filter(c => c.surveyResultId === s.id)
        .sort((a, b) => a.counselingReservationId - b.counselingReservationId);
      return resultCounselings.map(c => [
        c.counselingReservationId,
        `[${c.reservationTimestamp ? format(new Date(c.counseledTimestamp || c.reservationTimestamp), 'PPP(E) p', { locale: ja }) : '調整中'}] ${statusJp[c.status]}`,
      ]);
    } return [];
  }
  useEffect(() => {
    const promiseEmployee = employeeApi.employeesIdGet(id).then(res => {
      const orgEmployee = res.data;
      setEmployee(orgEmployee);
    });
    const promiseResult = surveyApi.surveyResultsGet(id).then(res => {
      setSurveyResults(res.data.items);
    });
    const promiseCounselings = counselingApi.counselingsGet(id).then(res => {
      setCounselings(res.data);
    });

    Promise.all([promiseEmployee, promiseResult, promiseCounselings]).finally(() => {
      setVisible(true);
    });
  }, [id, session]);

  if (employee && surveyResults) {
    return (
      <>
        <Box style={{ padding: 20 }}>
          <Paper>
            <Table aria-label="simple table" style={{ width: '800px' }}>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>従業員番号</strong>
                  </TableCell>
                  <TableCell className={styles.cell}>{employee.employeeNumber}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>氏名</strong>
                  </TableCell>
                  <TableCell className={styles.cell}>{employee.employeeName}</TableCell>
                  <TableCell />
                  <TableCell component="th" scope="row">
                    <strong>Email</strong>
                  </TableCell>
                  <TableCell className={styles.cell}>{employee.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>入社年月日</strong>
                  </TableCell>
                  <TableCell>{employee.joinedDate && format(new Date(employee.joinedDate), 'PPP(E) p', { locale: ja })}</TableCell>
                  <TableCell />
                  <TableCell component="th" scope="row">
                    <strong>年齢</strong>
                  </TableCell>
                  <TableCell>{employee.age}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
          <Box display="flex" mt={2}>
            <Box flexGrow={1} />
            <Button
              variant="contained"
              color="secondary"
              component={LinkForward(`/counselings/new?employeeId=${id}`)}
              className={styles.button}
            >
              <AddIcon />
              面談作成
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={styles.button}
              component={LinkForward(`/employees/${id}/edit`)}
            >
              <EditIcon />
              編集
            </Button>
          </Box>
        </Box>
        <Box style={{ padding: 20 }}>
          <Typography variant="h4" component="h1">
            アンケート結果一覧
          </Typography>

          <Paper>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>アンケート名</TableCell>
                  <TableCell>開始日時</TableCell>
                  <TableCell>完了日時</TableCell>
                  <TableCell>調査結果</TableCell>
                  <TableCell>面談</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {surveyResults ? surveyResults.map(s => (
                  <TableRow key={s.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/survey-results/${s.id}`}>
                        {s.title}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {s.startTimestamp && format(new Date(s.startTimestamp), 'PPP(E) p', { locale: ja })}
                    </TableCell>
                    <TableCell>
                      {s.finishTimestamp && format(new Date(s.finishTimestamp), 'PPP(E) p', { locale: ja })}
                    </TableCell>
                    <TableCell>{s.alerted ? <ErrorOutlineIcon color="error" /> : <CheckedIcon />}</TableCell>
                    <TableCell>
                      <ul>
                        {counselingInfos(s).map(info => (
                          <li><Link to={`/counselings/${info[0]}`}>{info[1]}</Link></li>
                        ))}
                      </ul>
                    </TableCell>
                  </TableRow>
                )) : <></>}
              </TableBody>
            </Table>
          </Paper>
        </Box>
        <Box style={{ padding: 20 }}>
          <Typography variant="h4" component="h1">
            面談記録一覧
          </Typography>

          <Paper>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>アンケート名</TableCell>
                  <TableCell>予定日時</TableCell>
                  <TableCell>実施日時</TableCell>
                  <TableCell>ステータス</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {counselings ? counselings.map(c => (
                  <TableRow key={c.counselingReservationId} hover onClick={() => history.push(`/counselings/${c.counselingReservationId}`)}>
                    <TableCell component="th" scope="row">
                      {c.surveyTitle}
                    </TableCell>
                    <TableCell>
                      {c.reservationTimestamp && format(new Date(c.reservationTimestamp), 'PPP(E) p', { locale: ja })}
                    </TableCell>
                    <TableCell>
                      {c.counseledTimestamp && format(new Date(c.counseledTimestamp), 'PPP(E) p', { locale: ja })}
                    </TableCell>
                    <TableCell>
                      {statusJp[c.status]}
                    </TableCell>
                  </TableRow>
                )) : <></>}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      </>
    );
  }
  if (!visible) {
    return (
      <CircularProgress />
    );
  }
  return <p>404 not found</p>;
};

export const EmployeeDetailPage = SideMenuLayout(withAuth(Content));
