import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  CircularProgress, Box, TablePagination,
} from '@material-ui/core';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { SideMenuLayout } from '../../layouts';
import { withAuth } from '../../auth/Authenticated';
import { AlertList } from '../../components/alert/AlertList';
import { usePrivateApi } from '../../api/useApi';
import { EmployeeApi, AlertApi, AlertsResponse } from '../../generated';
import { Employee, EmployeeList } from '../../components/employee/EmployeeList';

const Content = () => {
  const [employeePage, setEmployeePage] = useState<number>(0);
  const [alertPage, setAlertPage] = useState<number>(0);
  const [employeeTotal, setEmployeeTotal] = useState<number>(0);
  const [alertTotal, setAlertTotal] = useState<number>(0);
  const [employeeRows, setEmployeeRows] = useState(10);
  const [alertRows, setAlertRows] = useState(10);
  const [alerts, setAlerts] = useState<AlertsResponse>();
  const [employees, setEmployees] = useState<Employee[]>();
  const { api: alertApi, loading: alertLoading } = usePrivateApi(AlertApi);
  const { api: employeeApi, loading: employeeLoading } = usePrivateApi(EmployeeApi);
  const onChangeEmployeeRowsPerPage = (rowsPerPage: number) => {
    setEmployeePage(0);
    setEmployeeRows(rowsPerPage);
  };
  const onChangeAlertRowsPerPage = (rowsPerPage: number) => {
    setAlertPage(0);
    setAlertRows(rowsPerPage);
  };
  const labelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => `${from}-${to === -1 ? count : to} : ${count}件中`;

  useEffect(() => {
    employeeApi.employeesUnansweredGet(employeePage, employeeRows).then(res => {
      setEmployeeTotal(res.data.pagination.totalElements);
      setEmployees(res.data.items);
    });
  }, [employeePage, employeeRows]);

  useEffect(() => {
    alertApi.alertsGet(alertPage, alertRows).then(res => {
      setAlertTotal(res.data.pagination.totalElements);
      setAlerts(res.data);
    });
  }, [alertPage, alertRows]);

  if (alertLoading && employeeLoading) return <CircularProgress />;
  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" component="h1">
            ダッシュボード
          </Typography>
        </Box>
      </Box>
      <Box display="flex">
        <Box flexGrow={1} marginTop={2}>
          <Typography variant="h6" component="h2">
            アラート一覧
          </Typography>
        </Box>
      </Box>
      <AlertList alertsResponse={alerts} />
      <TablePagination
        component="div"
        page={alertPage}
        rowsPerPage={alertRows}
        count={alertTotal}
        labelRowsPerPage="表示件数"
        labelDisplayedRows={labelDisplayedRows}
        onChangePage={(_, p) => setAlertPage(p)}
        onChangeRowsPerPage={e => onChangeAlertRowsPerPage(Number(e.target.value))}
      />

      <Box display="flex">
        <Box flexGrow={1} marginTop={2}>
          <Typography variant="h6" component="h2">
            未回答一覧
          </Typography>
        </Box>
      </Box>
      <EmployeeList employees={employees} />
      <TablePagination
        component="div"
        page={employeePage}
        rowsPerPage={employeeRows}
        count={employeeTotal}
        labelRowsPerPage="表示件数"
        labelDisplayedRows={labelDisplayedRows}
        onChangePage={(_, p) => setEmployeePage(p)}
        onChangeRowsPerPage={e => onChangeEmployeeRowsPerPage(Number(e.target.value))}
      />
    </>
  );
};

export const DashboardPage = SideMenuLayout(withAuth(Content));
