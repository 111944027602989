import React, { useState, useMemo } from 'react';
import {
  Snackbar,
} from '@material-ui/core';

interface IProps {
  message: string | null;
  duration: number;
}

export const Toast: React.SFC<IProps> = ({ message, duration = 3000 }: IProps) => {
  const [hidden, setHidden] = useState(true);

  useMemo(() => {
    if (message) {
      setHidden(false);
    }
  }, [message]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={!hidden}
      autoHideDuration={duration}
      onClose={() => setHidden(true)}
      message={<span>{message}</span>}
    />
  );
};
