import axios, { AxiosInstance } from 'axios';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { createConfig, publicApiConfig } from './apiConfig';
import { clearSession } from '../redux/Session/SessionAction';
import { Configuration, ErrorResponse } from '../generated';
import { useSession } from '../foundation/Store';

/**
 * リクエスト開始、終了、認証エラーフック用のaxiosクライアント
 * @param onRequest
 * @param onFinish
 * @param onAuthError
 */
const createAxios = (
  onRequest: () => void,
  onFinish: () => void,
  onError: (err: any) => void,
  onAuthError: () => void,
) => {
  const axiosInstance = axios.create();
  axiosInstance.interceptors.request.use(req => {
    onRequest();
    return req;
  });
  axiosInstance.interceptors.response.use(res => {
    onFinish();
    return res;
  }, err => {
    onError(err);
    onFinish();
    if (err.response && err.response.status && err.response.status === 401) {
      onAuthError();
    }
    return Promise.reject(err);
  });

  return axiosInstance;
};

/**
 * 認証無しAPI生成用。
 * APIクラス名を渡すとclientのインスタンスやローディング状況、エラーメッセージを返してくれる
 * @param ApiConstructor
 */
export const usePublicApi = <Api>(
  ApiConstructor: new (config: Configuration, basePath?: string, axios?: AxiosInstance) => Api,
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const dispatch = useDispatch();

  const axiosInstance = createAxios(() => {
    setLoading(true);
    setError(null);
  }, () => {
    setLoading(false);
  }, err => {
    if (err.response && err.response.data) {
      setError(err.response.data);
    }
  }, () => {
    dispatch(clearSession());
  });

  const api = new ApiConstructor(publicApiConfig, undefined, axiosInstance);

  return { api, loading, error };
};

/**
 * 認証有りAPI生成用。
 * APIクラス名を渡すとclientのインスタンスやローディング状況、エラーメッセージを返してくれる。
 * 401時は強制的にclearSessionする。
 * @param ApiConstructor
 */
export const usePrivateApi = <Api>(
  ApiConstructor: new (config: Configuration, basePath?: string, axios?: AxiosInstance) => Api,
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const dispatch = useDispatch();
  const session = useSession();

  if (session === 'guest') {
    // 強制ログアウト
    dispatch(clearSession());
    throw Error('not authed');
  }

  const config = createConfig(session);
  const axiosInstance = createAxios(() => {
    setLoading(true);
    setError(null);
  }, () => {
    setLoading(false);
  }, err => {
    if (err.response && err.response.data) {
      setError(err.response.data);
    }
  }, () => {
    dispatch(clearSession());
  });

  const api = new ApiConstructor(config, undefined, axiosInstance);

  return { api, loading, error };
};
