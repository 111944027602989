import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  CircularProgress, Box, Grid, Button,
} from '@material-ui/core';
import { SideMenuLayout } from '../../layouts';
import { withAuth, AuthedComponentProps } from '../../auth/Authenticated';
import { CounselingList } from '../../components/CounselingList';
import { CurrentContractInfo } from '../../components/CurrentContractInfo';
import { LinkForward } from '../../components/common/LinkForward';
import { usePrivateApi } from '../../api/useApi';
import {
  CounselingApi, ContractApi, CurrentContractResponse, CounselingResponse,
} from '../../generated';

const Content = ({ session }: AuthedComponentProps) => {
  const [counselings, setCounselings] = useState<CounselingResponse[]>();
  const [loading, setLoading] = useState(false);
  const { api: counselingApi } = usePrivateApi(CounselingApi);
  const { api: contractApi } = usePrivateApi(ContractApi);
  const [contractInfo, setContractInfo] = useState<CurrentContractResponse>();

  useEffect(() => {
    setLoading(true);
    const counselingReq = counselingApi.counselingsGet().then(res => {
      setCounselings(res.data);
    });
    const contractReq = contractApi.currentContractGet().then(res => {
      setContractInfo(res.data);
    });
    Promise.all([counselingReq, contractReq]).finally(() => {
      setLoading(false);
    });
  }, [session]);
  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" component="h1">
            面談記録一覧
          </Typography>
        </Box>
        <Box alignSelf="flex-end">
          <Button
            variant="contained"
            color="primary"
            component={LinkForward('/counselings/bulk-new')}
            style={{ margin: '10px', height: '46px' }}
          >
            一括作成
          </Button>
        </Box>
        <Box>
          <CurrentContractInfo contractInfo={contractInfo} />
        </Box>
      </Box>
      {loading
        ? (
          <Grid container justify="center">
            <CircularProgress />
          </Grid>
        )
        : <CounselingList counselings={counselings} />
      }
    </>
  );
};

export const CounselingIndexPage = SideMenuLayout(withAuth(Content));
