/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import {
  Grid,
  Button,
  Box,
  TextField,
  FormHelperText,
  CircularProgress,
  Checkbox,
  Link,
} from '@material-ui/core';
import firebase from 'firebase';
import { useDispatch } from 'react-redux';
import useForm from 'react-hook-form';
import { MainLayout } from '../../layouts';
import { SessionState } from '../../redux/Session/SessionState';
import { updateSession } from '../../redux/Session/SessionAction';
import { usePublicApi } from '../../api/useApi';
import { AccountRegisterRequest, AccountApi } from '../../generated';
import { Toast } from '../../components/common/Toast';

// TODO: 一箇所にまとめる
const isEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const Content: React.SFC = () => {
  const {
    register, handleSubmit, errors, getValues, watch,
  } = useForm();
  const { history } = useReactRouter();
  const { api, loading, error } = usePublicApi(AccountApi);
  const [message, setMessage] = useState<string | null>(null);
  const dispatch = useDispatch();

  const emailRef = register({
    required: '必須項目です',
    validate: v => isEmail(v) || '正しいEメールアドレスではありません',
  });

  const passwordRef = register({
    required: '必須項目です',
    minLength: {
      value: 8,
      message: '8文字以上必要です',
    },
  });

  const passwordConfirmRef = register({
    required: '必須項目です',
    minLength: {
      value: 8,
      message: '8文字以上必要です',
    },
    validate: v => v === watch('password') || 'パスワードと確認用パスワードが一致しません',
  });

  const agreementRef = register({
    required: '利用規約に同意する必要があります',
  });

  const login = async () => {
    setMessage(null);
    const values = getValues();
    const {
      email, password,
    } = values;
    if (typeof email !== 'string' || typeof password !== 'string') {
      throw new Error('Unexpected Type: email or password or organization is incorrect.');
    } else {
      const param: AccountRegisterRequest = {
        email,
        password,
      };
      try {
        const response = await api.accountsRegisterPost(param);
        const account = response.data;

        // 最初にfirebaseにid/passを投げてユーザを作成する
        const res: firebase.auth.UserCredential = await firebase.auth()
          .signInWithCustomToken(account.token);
        const { user } = res;
        if (user) {
          const token = await user.getIdToken();

          // 確認メールを送信
          await user.sendEmailVerification({ url: `${window.location.origin}/` });

          const session: SessionState = {
            principal: { name: account.name, id: String(account.userId) },
            isVerified: user.emailVerified,
            token,
            expireAt: new Date(), // TODO: set expiration time
          };
          // firebaseから取得したトークンとUserIDをバックエンドのAPIに投げてアカウントを作成
          dispatch(updateSession(session));
          history.push('/');
        }
      } catch (_error) {
        setMessage('アカウント作成に失敗しました');
      }
    }
  };

  const onSubmit = () => {
    login();
  };

  const errorMessage = () => {
    if (error && error.code === 404) {
      return 'このメールアドレスはご利用いただけません。\n組織で割り当てられているメールアドレスをご利用ください。';
    } if (error) {
      return 'アカウント作成に失敗しました。';
    } if (message) {
      return message;
    }
    return null;
  };

  return (
    <Grid
      container
      alignContent="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid
        item
        style={{
          background: 'white',
          borderRadius: '5px',
          width: '500px',
          marginBottom: '10px',
          padding: '20px',
        }}
      >
        <Box p={2}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box p={1}>
              <TextField
                type="text"
                name="email"
                label="Email"
                fullWidth
                inputRef={emailRef}
              />
              {
                errors.email && (
                  <FormHelperText error>
                    {errors.email.message}
                  </FormHelperText>
                )
              }
            </Box>
            <Box p={1}>
              <TextField
                type="password"
                name="password"
                label="パスワード"
                fullWidth
                inputRef={passwordRef}
              />
              {
                errors.password && (
                  <FormHelperText error>
                    {errors.password.message}
                  </FormHelperText>
                )
              }
            </Box>
            <Box p={1}>
              <TextField
                type="password"
                name="passwordConfirm"
                label="パスワード(確認用)"
                fullWidth
                inputRef={passwordConfirmRef}
              />
              {
                errors.passwordConfirm && (
                  <FormHelperText error>
                    {errors.passwordConfirm.message}
                  </FormHelperText>
                )
              }
            </Box>
            <Box p={1}>
              <Checkbox name="agreement" color="primary" inputRef={agreementRef} />
              <Link to="/about/terms" target="blank" component={RouterLink}>利用規約</Link>
              に同意する
              {
                errors.agreement && (
                  <FormHelperText error>
                    {errors.agreement.message}
                  </FormHelperText>
                )
              }
            </Box>
            <Box p={1}>
              <Button
                type="submit"
                color="primary"
                disabled={loading}
                fullWidth
                style={{ lineHeight: '36px' }}
              >
                {loading && <CircularProgress size={20} />}
                アカウント新規作成
              </Button>
            </Box>
          </form>
          <Box p={1} style={{ textAlign: 'center' }}>
            アカウントをお持ちの方は
            <Link to="/auth/signin" component={RouterLink}>ログイン</Link>
            から
          </Box>
        </Box>

        <Toast
          message={errorMessage()}
          duration={3000}
        />
      </Grid>
    </Grid>
  );
};


export const RegisterAccountPage = MainLayout(Content);
