import { CounterState, init } from './CounterState';
import { CountAction, INCREMENT, DECREMENT } from './CounterAction';

export const counterReducer = (
  { count }: CounterState = init(),
  action: CountAction,
): CounterState => {
  switch (action.type) {
    case INCREMENT:
      return { count: count + 1 };
    case DECREMENT:
      return { count: count - 1 };
    default:
      return { count };
  }
};
