import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  List,
  ListItem,
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import * as Icons from '@material-ui/icons';
import { LinkForward } from './common/LinkForward';
import { Logo } from './common/Logo';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: {
    ...theme.mixins.toolbar,
    paddingBottom: '10px',
  },
}));

// TODO: ListItemのcomponentにインラインで関数を渡さないようにする
// https://material-ui.com/guides/composition/
export const Navigation = () => {
  const classes = useStyles({});
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <Logo />
      </div>
      <List>
        <ListItem
          button
          component={LinkForward('/')}
        >
          <ListItemIcon><Icons.BarChart /></ListItemIcon>
          <ListItemText primary="ダッシュボード" />
        </ListItem>
        <ListItem
          button
          component={LinkForward('/survey-deliveries')}
        >
          <ListItemIcon><Icons.Mail /></ListItemIcon>
          <ListItemText primary="アンケート配信" />
        </ListItem>
        <ListItem
          button
          component={LinkForward('/survey-results')}
        >
          <ListItemIcon><InboxIcon /></ListItemIcon>
          <ListItemText primary="アンケート結果" />
        </ListItem>
        <ListItem
          button
          component={LinkForward('/counselings')}
        >
          <ListItemIcon><Icons.Schedule /></ListItemIcon>
          <ListItemText primary="面談" />
        </ListItem>
        <ListItem
          button
          component={LinkForward('/employees')}
        >
          <ListItemIcon><Icons.Person /></ListItemIcon>
          <ListItemText primary="従業員" />
        </ListItem>
      </List>
    </Drawer>
  );
};
