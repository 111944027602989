import React, { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import useForm from 'react-hook-form';
import {
  Box,
  Typography,
  Button,
  Paper,
  FormHelperText,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { SideMenuLayout } from '../../layouts';
import { withAuth, AuthedComponentProps } from '../../auth/Authenticated';
import { EmployeeRequest, EmployeeApi } from '../../generated';
import { usePrivateApi } from '../../api/useApi';
import { Toast } from '../../components/common/Toast';

interface FormData {
  employeeNumber: string;
  employeeName: string;
  email: string;
  age?: number;
  joinedDate?: string;
}

const isEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const Content: React.FunctionComponent<AuthedComponentProps> = (
  { session }: AuthedComponentProps,
) => {
  const { history, match } = useReactRouter<{ id: string }>();
  const isEditPage = match.path.includes('edit');
  const id = Number(match.params.id);
  const {
    register, handleSubmit, errors, getValues, setValue,
  } = useForm<FormData>();
  const { api, loading, error } = usePrivateApi(EmployeeApi);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // 編集ページのURLの場合はemployeeIdの従業員のデータを取ってきてフォームに入れておく
    if (isEditPage) {
      const request = api.employeesIdGet(id);
      request.then(res => {
        const orgEmployee = res.data;
        if (orgEmployee.employeeNumber) {
          setValue('employeeNumber', orgEmployee.employeeNumber);
        }
        setValue('employeeName', orgEmployee.employeeName);
        setValue('email', orgEmployee.email);
        setVisible(true);
      });
    } else {
      setVisible(true);
    }
  }, [id, session]);


  const onSubmit = () => {
    const param: EmployeeRequest = getValues();
    const request = isEditPage
      ? api.employeesIdPut(id, param)
      : api.employeesPost(param);
    request.then(_res => {
      history.push('/employees');
    });
  };

  const employeeNameRef = register({
    required: '必須項目です',
  });

  const employeeNumberRef = register({
    required: '必須項目です',
  });

  const emailRef = register({
    required: '必須項目です',
    validate: v => isEmail(v) || '正しいEメールアドレスではありません',
  });

  const errorMessage = () => {
    if (error && isEditPage) {
      return '従業員の更新に失敗しました';
    } if (error) {
      return '従業員の登録に失敗しました';
    }
    return null;
  };

  if (loading && !visible) return <CircularProgress />;

  return (
    <>
      <Typography variant="h4" component="h1">
        {isEditPage ? <>従業員編集</> : <>従業員作成</>}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          <Box p={2}>
            <Typography variant="h6" component="h3">従業員番号</Typography>
            <TextField
              type="text"
              name="employeeNumber"
              inputRef={employeeNumberRef}
            />
            {
              errors.employeeNumber && (
                <FormHelperText error>
                  {errors.employeeNumber.message}
                </FormHelperText>
              )
            }
          </Box>
          <Box p={2}>
            <Typography variant="h6" component="h3">従業員氏名</Typography>
            <TextField
              type="text"
              name="employeeName"
              placeholder="えむたろう"
              inputRef={employeeNameRef}
            />
            {
              errors.employeeName && (
                <FormHelperText error>
                  {errors.employeeName.message}
                </FormHelperText>
              )
            }
          </Box>
          <Box p={2}>
            <Typography variant="h6" component="h3">従業員メールアドレス</Typography>
            <TextField
              type="text"
              name="email"
              placeholder="m-taro@m3.com"
              inputRef={emailRef}
            />
            {
              errors.email && (
                <FormHelperText error>
                  {errors.email.message}
                </FormHelperText>
              )
            }
          </Box>
        </Paper>
        <Box display="flex" mt={2}>
          <Box flexGrow={1} />
          <Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : <EditIcon />}
              {isEditPage ? <>更新する</> : <>登録する</>}
            </Button>
          </Box>
        </Box>
      </form>
      <Toast
        message={errorMessage()}
        duration={3000}
      />
    </>
  );
};

export const EmployeeCreatePage = SideMenuLayout(withAuth(Content));
