import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

export interface SurveyDelivery {
  surveyDeliveryId: string;
  surveyTitle: string;
  surveyDeliveryDate: Date;
}

// react-routerからhistoryを受け取るための型
interface IProps extends RouteComponentProps<any> {
  surveyDeliveries?: SurveyDelivery[];
}

const Content = ({ history, surveyDeliveries }: IProps) => {
  const classes = useStyles({});

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>アンケート配信ID</TableCell>
            <TableCell>アンケート名</TableCell>
            <TableCell>アンケート配信予定日時</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {surveyDeliveries ? surveyDeliveries.map(s => (
            <TableRow key={s.surveyDeliveryId} hover onClick={() => history.push(`/survey-deliveries/${s.surveyDeliveryId}`)}>
              <TableCell component="th" scope="row">
                {s.surveyDeliveryId}
              </TableCell>
              <TableCell>{s.surveyTitle}</TableCell>
              <TableCell>{format(new Date(s.surveyDeliveryDate), 'PPP(E) p', { locale: ja })}</TableCell>
            </TableRow>
          )) : <></>}
        </TableBody>
      </Table>
    </Paper>
  );
};

export const SurveyDeliveryList = withRouter(Content);
