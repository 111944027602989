import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  CircularProgress,
  Typography,
  Button,
  TablePagination,
} from '@material-ui/core';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { LinkForward } from '../../components/common/LinkForward';
import { SideMenuLayout } from '../../layouts';
import { Employee, EmployeeList } from '../../components/employee/EmployeeList';
import { withAuth, AuthedComponentProps } from '../../auth/Authenticated';
import { usePrivateApi } from '../../api/useApi';
import { EmployeeApi } from '../../generated';

const Content: React.FunctionComponent<AuthedComponentProps> = (
  { session }: AuthedComponentProps,
) => {
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState<Employee[]>();
  const { api } = usePrivateApi(EmployeeApi);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [rows, setRows] = useState(10);
  const onChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(0);
    setRows(rowsPerPage);
  };
  const labelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => `${from}-${to === -1 ? count : to} : ${count}件中`;

  useEffect(() => {
    setLoading(true);
    api.employeesGet(page, rows).then(res => {
      setTotal(res.data.pagination.totalElements);
      setEmployees(res.data.items);
    }).catch(_ => {
    }).finally(() => {
      setLoading(false);
    });
  }, [session, page, rows]);

  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" component="h1">
            従業員一覧ページ
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            component={LinkForward('/employees/new')}
          >
            従業員追加
          </Button>
        </Box>
      </Box>
      {
        loading
          ? (
            <Grid container justify="center">
              <CircularProgress />
            </Grid>
          )
          : (
            <Box marginTop={3}>
              <EmployeeList employees={employees} />
              <TablePagination
                component="div"
                page={page}
                rowsPerPage={rows}
                count={total}
                labelRowsPerPage="表示件数"
                labelDisplayedRows={labelDisplayedRows}
                onChangePage={(_, p) => setPage(p)}
                onChangeRowsPerPage={e => onChangeRowsPerPage(Number(e.target.value))}
              />
            </Box>
          )

      }
    </>
  );
};

export const EmployeeIndexPage = SideMenuLayout(withAuth(Content));
