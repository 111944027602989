import { LoggedIn } from '../redux/Session/SessionState';

export interface ApiConfig {
  basePath: string;
  accessToken?: string;
}

const BASE_PATH: string = process.env.REACT_APP_API_URL as string;

export const createConfig = (session: LoggedIn): ApiConfig => ({
  basePath: BASE_PATH,
  accessToken: session.token,
});

export const publicApiConfig = {
  basePath: BASE_PATH,
};
