import React from 'react';
import {
  Paper, Table, TableHead, TableRow, TableCell, TableBody, Theme, makeStyles, createStyles,
} from '@material-ui/core';
import useReactRouter from 'use-react-router';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { CounselingResponse } from '../generated';

const statusJp: { [key: string]: string } = { reservation: '面談未実施', cancel: 'キャンセル済', counseled: '実施済' };
const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));


interface IProps {
  counselings?: CounselingResponse[];
}

const Content = ({ counselings }: IProps) => {
  const classes = useStyles({});
  const { history } = useReactRouter();

  function displayStatus(counseling: CounselingResponse) {
    if (counseling.status === 'reservation' && !counseling.reservationTimestamp) {
      if (!counseling.emergencyContact) {
        return '従業員回答待ち';
      } return '日程調整中';
    }
    return statusJp[counseling.status];
  }

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>アンケート名</TableCell>
            <TableCell>従業員名</TableCell>
            <TableCell>予約日時</TableCell>
            <TableCell>ステータス</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {counselings ? counselings.map(c => (
            <TableRow key={c.counselingReservationId} hover onClick={() => history.push(`/counselings/${c.counselingReservationId}`)}>
              <TableCell component="th" scope="row">{c.surveyTitle}</TableCell>
              <TableCell>{c.employeeName}</TableCell>
              <TableCell>{c.reservationTimestamp && format(new Date(c.reservationTimestamp), 'PPP(E) p', { locale: ja })}</TableCell>
              <TableCell>{displayStatus(c)}</TableCell>
            </TableRow>
          )) : <></>}
        </TableBody>
      </Table>
    </Paper>

  );
};

export const CounselingList = Content;
