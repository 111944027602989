import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Typography,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import useReactRouter from 'use-react-router';
import SaveIcon from '@material-ui/icons/Save';
import { SideMenuLayout } from '../../layouts';
import {
  CreateCounselingRequest, CounselingApi, SurveyResultDetailResponse, SurveyResultApi,
  EmployeeResponse, EmployeeApi,
} from '../../generated';
import { withAuth, AuthedComponentProps } from '../../auth/Authenticated';
import { usePrivateApi } from '../../api/useApi';

const Content: React.FunctionComponent<AuthedComponentProps> = (
  { session }: AuthedComponentProps,
) => {
  const { match, history, location } = useReactRouter();
  const [loading, setLoading] = useState(false);
  const [surveyResult, setSurveyResult] = useState<SurveyResultDetailResponse>();
  const [employee, setEmployee] = useState<EmployeeResponse>();
  const { api: counselingApi } = usePrivateApi(CounselingApi);
  const { api: surveyResultApi } = usePrivateApi(SurveyResultApi);
  const { api: employeeApi } = usePrivateApi(EmployeeApi);

  const submit = () => {
    setLoading(true);
    const query = new URLSearchParams(location.search);
    const surveyResultId = query.get('surveyResultId') != null ? Number(query.get('surveyResultId')) : undefined;
    const employeeId = query.get('employeeId') != null ? Number(query.get('employeeId')) : undefined;
    const newCounseling: CreateCounselingRequest = {
      surveyResultId,
      employeeId,
    };
    const req = counselingApi.counselingsPost(newCounseling);
    req.then(() => {
      history.push('/counselings');
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    const query = new URLSearchParams(location.search);
    const surveyResultId = Number(query.get('surveyResultId'));
    if (surveyResultId) {
      const requestSurveyResult = surveyResultApi.surveyResultsIdGet(surveyResultId);
      requestSurveyResult.then(surveyResultRes => {
        setSurveyResult(surveyResultRes.data);
      }).finally(() => {
        setLoading(false);
      });
    }

    const employeeId = Number(query.get('employeeId'));
    if (employeeId) {
      const requestEmployee = employeeApi.employeesIdGet(employeeId);
      requestEmployee.then(employeeRes => {
        setEmployee(employeeRes.data);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [match, session]);

  if (loading) return <CircularProgress />;

  return (
    <>
      <Typography variant="h4" component="h1">
        面談記録作成ページ
      </Typography>
      <Paper>
        <Box p={2}>
          <Typography variant="h6" component="h3">従業員名</Typography>
          <Typography component="p">{(employee && employee.employeeName) || (surveyResult && surveyResult.employeeName)}</Typography>
        </Box>
        <Box p={2}>
          <Typography variant="h6" component="h3">従業員email</Typography>
          <Typography component="p">{(employee && employee.email) || (surveyResult && surveyResult.employeeEmail)}</Typography>
        </Box>
        <Box p={2}>
          <Typography variant="h6" component="h3">アンケート名</Typography>
          <Typography component="p">{surveyResult && surveyResult.title}</Typography>
        </Box>
        <Box p={2} />
      </Paper>
      <Box display="flex" mt={2}>
        <Box flexGrow={1} />
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={submit}
          >
            <SaveIcon />
            保存する
          </Button>
        </Box>
      </Box>
    </>
  );
};

export const CounselingCreatePage = SideMenuLayout(withAuth(Content));
