import React from 'react';
import userReactRouter from 'use-react-router';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

export interface Employee {
  id: number;
  employeeNumber?: string;
  employeeName: string;
  age?: number;
  email: string;
  joinedDate?: string;
}

interface IProps {
  employees?: Employee[];
}

const Content = ({ employees }: IProps) => {
  const classes = useStyles({});
  const { history } = userReactRouter();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>従業員番号</TableCell>
            <TableCell>従業員名</TableCell>
            <TableCell>メールアドレス</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {employees ? employees.map(e => (
            <TableRow key={e.id} hover onClick={() => history.push(`/employees/${e.id}`)}>
              <TableCell>{e.employeeNumber}</TableCell>
              <TableCell>{e.employeeName}</TableCell>
              <TableCell>{e.email}</TableCell>
            </TableRow>
          )) : <></>}
        </TableBody>
      </Table>
    </Paper>
  );
};

export const EmployeeList = Content;
