// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Organization API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 認証リクエスト
 * @export
 * @interface AccountAuthRequest
 */
export interface AccountAuthRequest {
    /**
     * 登録emailアドレス
     * @type {string}
     * @memberof AccountAuthRequest
     */
    email: string;
    /**
     * Firebase ID Token
     * @type {string}
     * @memberof AccountAuthRequest
     */
    token: string;
}
/**
 * オーナーアカウント作成リクエスト(組織とアカウント同時作成)
 * @export
 * @interface AccountCreateRequest
 */
export interface AccountCreateRequest {
    /**
     * 組織名
     * @type {string}
     * @memberof AccountCreateRequest
     */
    organizationName: string;
    /**
     * 名前
     * @type {string}
     * @memberof AccountCreateRequest
     */
    name: string;
    /**
     * 登録emailアドレス
     * @type {string}
     * @memberof AccountCreateRequest
     */
    email: string;
    /**
     * パスワード
     * @type {string}
     * @memberof AccountCreateRequest
     */
    password: string;
}
/**
 * アカウント正式登録リクエスト
 * @export
 * @interface AccountRegisterRequest
 */
export interface AccountRegisterRequest {
    /**
     * 登録emailアドレス
     * @type {string}
     * @memberof AccountRegisterRequest
     */
    email: string;
    /**
     * パスワード
     * @type {string}
     * @memberof AccountRegisterRequest
     */
    password: string;
}
/**
 * 認証レスポンス
 * @export
 * @interface AccountResponse
 */
export interface AccountResponse {
    /**
     * ユーザID(Firebaseのものではなくシステム上のID)
     * @type {number}
     * @memberof AccountResponse
     */
    userId: number;
    /**
     * メールアドレス
     * @type {string}
     * @memberof AccountResponse
     */
    email: string;
    /**
     * 氏名
     * @type {string}
     * @memberof AccountResponse
     */
    name: string;
    /**
     * Firebaseカスタムトークン
     * @type {string}
     * @memberof AccountResponse
     */
    token: string;
}
/**
 * アラート詳細
 * @export
 * @interface AlertDetailResponse
 */
export interface AlertDetailResponse {
    /**
     * アラートID
     * @type {number}
     * @memberof AlertDetailResponse
     */
    id: number;
    /**
     * 従業員ID
     * @type {number}
     * @memberof AlertDetailResponse
     */
    employeeId: number;
    /**
     * 従業員名
     * @type {string}
     * @memberof AlertDetailResponse
     */
    employeeName: string;
    /**
     * アラートラベル
     * @type {Array<string>}
     * @memberof AlertDetailResponse
     */
    labels: Array<string>;
    /**
     * アンケート結果ID
     * @type {number}
     * @memberof AlertDetailResponse
     */
    surveyResultId?: number;
    /**
     * 作成日時
     * @type {Date}
     * @memberof AlertDetailResponse
     */
    createdAt: Date;
}
/**
 * アラート
 * @export
 * @interface AlertResponse
 */
export interface AlertResponse {
    /**
     * アラートID
     * @type {number}
     * @memberof AlertResponse
     */
    id: number;
    /**
     * アラートラベル
     * @type {Array<string>}
     * @memberof AlertResponse
     */
    labels: Array<string>;
}
/**
 * アラート一覧
 * @export
 * @interface AlertsResponse
 */
export interface AlertsResponse {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof AlertsResponse
     */
    pagination: PaginationResponse;
    /**
     * アラート
     * @type {Array<AlertDetailResponse>}
     * @memberof AlertsResponse
     */
    alerts: Array<AlertDetailResponse>;
}
/**
 * 設問回答
 * @export
 * @interface AnswerResponse
 */
export interface AnswerResponse {
    /**
     * 設問回答ID
     * @type {number}
     * @memberof AnswerResponse
     */
    id: number;
    /**
     * 設問ID
     * @type {number}
     * @memberof AnswerResponse
     */
    questionId: number;
    /**
     * 値
     * @type {string}
     * @memberof AnswerResponse
     */
    value: string;
}
/**
 * 面談調整一括作成リクエスト
 * @export
 * @interface BulkCreateCounselingRequest
 */
export interface BulkCreateCounselingRequest {
    /**
     * 従業員ID配列
     * @type {Array<number>}
     * @memberof BulkCreateCounselingRequest
     */
    employeeIds: Array<number>;
}
/**
 * アンケート設問の選択肢
 * @export
 * @interface ChoiceResponse
 */
export interface ChoiceResponse {
    /**
     * アンケート設問回答ID
     * @type {number}
     * @memberof ChoiceResponse
     */
    id: number;
    /**
     * 設問回答表示名
     * @type {string}
     * @memberof ChoiceResponse
     */
    title: string;
    /**
     * アンケート設問回答値
     * @type {string}
     * @memberof ChoiceResponse
     */
    value: string;
}
/**
 * アドバイス項目
 * @export
 * @enum {string}
 */
export enum CounselingAdvice {
    Body = 'body',
    Mental = 'mental',
    Relation = 'relation',
    Other = 'other',
    ReportDisagreement = 'reportDisagreement'
}

/**
 * 面談記録
 * @export
 * @interface CounselingDetailResponse
 */
export interface CounselingDetailResponse {
    /**
     * 面談予約ID
     * @type {number}
     * @memberof CounselingDetailResponse
     */
    counselingReservationId: number;
    /**
     * 調査結果ID
     * @type {number}
     * @memberof CounselingDetailResponse
     */
    surveyResultId?: number;
    /**
     * 従業員ID
     * @type {number}
     * @memberof CounselingDetailResponse
     */
    employeeId?: number;
    /**
     * 従業員Email
     * @type {string}
     * @memberof CounselingDetailResponse
     */
    employeeEmail: string;
    /**
     * カウンセラーID
     * @type {number}
     * @memberof CounselingDetailResponse
     */
    counselorId?: number;
    /**
     * 面談予定日時
     * @type {Date}
     * @memberof CounselingDetailResponse
     */
    reservationTimestamp?: Date;
    /**
     * 面談実施日時
     * @type {Date}
     * @memberof CounselingDetailResponse
     */
    counseledTimestamp?: Date;
    /**
     * 従業員名
     * @type {string}
     * @memberof CounselingDetailResponse
     */
    employeeName: string;
    /**
     * アンケート名
     * @type {string}
     * @memberof CounselingDetailResponse
     */
    surveyTitle?: string;
    /**
     * 面談コメント
     * @type {string}
     * @memberof CounselingDetailResponse
     */
    comment?: string;
    /**
     * 面談実施ステータス
     * @type {string}
     * @memberof CounselingDetailResponse
     */
    status: CounselingDetailResponseStatusEnum;
    /**
     * 面談の内容
     * @type {Array<CounselingResultItem>}
     * @memberof CounselingDetailResponse
     */
    counselingResultItems: Array<CounselingResultItem>;
    /**
     * 次回面談の日時
     * @type {Date}
     * @memberof CounselingDetailResponse
     */
    nextCounselingTimestamp?: Date;
    /**
     * 従業員コメント
     * @type {string}
     * @memberof CounselingDetailResponse
     */
    employeeComment?: string;
    /**
     * 従業員緊急連絡先
     * @type {string}
     * @memberof CounselingDetailResponse
     */
    emergencyContact?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CounselingDetailResponseStatusEnum {
    Reservation = 'reservation',
    Cancel = 'cancel',
    Counseled = 'counseled'
}

/**
 * 面談記録作成フォームの要素
 * @export
 * @interface CounselingFormItem
 */
export interface CounselingFormItem {
    /**
     * 
     * @type {string}
     * @memberof CounselingFormItem
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CounselingFormItem
     */
    displayName: string;
}
/**
 * 面談記録フォーム
 * @export
 * @interface CounselingFormResponse
 */
export interface CounselingFormResponse {
    /**
     * 面談のきっかけ
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    counselingOpportunity: Array<CounselingFormItem>;
    /**
     * アドバイス項目
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    counselingAdvice: Array<CounselingFormItem>;
    /**
     * 主なストレス要因
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    mainStressor: Array<CounselingFormItem>;
    /**
     * 緊急度
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    urgency: Array<CounselingFormItem>;
    /**
     * 離職する可能性
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    retirementPossibility: Array<CounselingFormItem>;
    /**
     * 休職する可能性
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    absencePossibility: Array<CounselingFormItem>;
    /**
     * 産業医面談
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    industrialDoctorCounseling: Array<CounselingFormItem>;
    /**
     * 受診勧奨
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    encourageConsultation: Array<CounselingFormItem>;
    /**
     * 人事対応
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    hrSupport: Array<CounselingFormItem>;
    /**
     * 組織対応
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    organizationalSupport: Array<CounselingFormItem>;
    /**
     * 業務上の配慮
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    businessConsiderations: Array<CounselingFormItem>;
    /**
     * 人事労務担当者への直接報告
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    reportToHr: Array<CounselingFormItem>;
    /**
     * 次回面談
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    nextCounseling: Array<CounselingFormItem>;
}
/**
 * 面談記録
 * @export
 * @interface CounselingResponse
 */
export interface CounselingResponse {
    /**
     * 面談予約ID
     * @type {number}
     * @memberof CounselingResponse
     */
    counselingReservationId: number;
    /**
     * 調査結果ID
     * @type {number}
     * @memberof CounselingResponse
     */
    surveyResultId?: number;
    /**
     * 従業員ID
     * @type {number}
     * @memberof CounselingResponse
     */
    employeeId?: number;
    /**
     * 従業員Email
     * @type {string}
     * @memberof CounselingResponse
     */
    employeeEmail: string;
    /**
     * カウンセラーID
     * @type {number}
     * @memberof CounselingResponse
     */
    counselorId?: number;
    /**
     * 面談予定日時
     * @type {Date}
     * @memberof CounselingResponse
     */
    reservationTimestamp?: Date;
    /**
     * 面談実施日時
     * @type {Date}
     * @memberof CounselingResponse
     */
    counseledTimestamp?: Date;
    /**
     * 従業員名
     * @type {string}
     * @memberof CounselingResponse
     */
    employeeName: string;
    /**
     * アンケート名
     * @type {string}
     * @memberof CounselingResponse
     */
    surveyTitle: string;
    /**
     * 面談コメント
     * @type {string}
     * @memberof CounselingResponse
     */
    comment?: string;
    /**
     * 面談実施ステータス
     * @type {string}
     * @memberof CounselingResponse
     */
    status: CounselingResponseStatusEnum;
    /**
     * 従業員コメント
     * @type {string}
     * @memberof CounselingResponse
     */
    employeeComment?: string;
    /**
     * 従業員緊急連絡先
     * @type {string}
     * @memberof CounselingResponse
     */
    emergencyContact?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CounselingResponseStatusEnum {
    Reservation = 'reservation',
    Cancel = 'cancel',
    Counseled = 'counseled'
}

/**
 * 面談結果要素
 * @export
 * @interface CounselingResultItem
 */
export interface CounselingResultItem {
    /**
     * 質問コード
     * @type {string}
     * @memberof CounselingResultItem
     */
    titleCode: string;
    /**
     * 質問表示名
     * @type {string}
     * @memberof CounselingResultItem
     */
    titleDisplayName: string;
    /**
     * 面談の結果
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingResultItem
     */
    answer: Array<CounselingFormItem>;
    /**
     * 面談フォーム種別
     * @type {string}
     * @memberof CounselingResultItem
     */
    formType?: CounselingResultItemFormTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CounselingResultItemFormTypeEnum {
    Checkbox = 'checkbox',
    Pulldown = 'pulldown',
    Text = 'text'
}

/**
 * 面談記録作成リクエスト
 * @export
 * @interface CreateCounselingRequest
 */
export interface CreateCounselingRequest {
    /**
     * 従業員ID
     * @type {number}
     * @memberof CreateCounselingRequest
     */
    employeeId?: number;
    /**
     * アンケート結果ID
     * @type {number}
     * @memberof CreateCounselingRequest
     */
    surveyResultId?: number;
}
/**
 * 今期契約情報レスポンス
 * @export
 * @interface CurrentContractResponse
 */
export interface CurrentContractResponse {
    /**
     * 組織ID
     * @type {number}
     * @memberof CurrentContractResponse
     */
    organizationId: number;
    /**
     * 契約済面談回数
     * @type {number}
     * @memberof CurrentContractResponse
     */
    contractedCounselingsCount: number;
    /**
     * 予約済み面談数
     * @type {number}
     * @memberof CurrentContractResponse
     */
    reservedCounselingsCount: number;
    /**
     * 実施済み面談数
     * @type {number}
     * @memberof CurrentContractResponse
     */
    usedCounselingsCount: number;
    /**
     * 契約開始日
     * @type {string}
     * @memberof CurrentContractResponse
     */
    startDate: string;
    /**
     * 契約終了日
     * @type {string}
     * @memberof CurrentContractResponse
     */
    endDate: string;
}
/**
 * 従業員登録リクエスト
 * @export
 * @interface EmployeeRequest
 */
export interface EmployeeRequest {
    /**
     * 従業員名
     * @type {string}
     * @memberof EmployeeRequest
     */
    employeeName: string;
    /**
     * 社員番号(組織内での管理番号)
     * @type {string}
     * @memberof EmployeeRequest
     */
    employeeNumber: string;
    /**
     * 年齢
     * @type {number}
     * @memberof EmployeeRequest
     */
    age?: number;
    /**
     * 入社年月日
     * @type {string}
     * @memberof EmployeeRequest
     */
    joinedDate?: string;
    /**
     * 従業員Eメールアドレス
     * @type {string}
     * @memberof EmployeeRequest
     */
    email: string;
}
/**
 * 従業員レスポンス
 * @export
 * @interface EmployeeResponse
 */
export interface EmployeeResponse {
    /**
     * 従業員ID(mindex内管理用ID)
     * @type {number}
     * @memberof EmployeeResponse
     */
    id: number;
    /**
     * 従業員名
     * @type {string}
     * @memberof EmployeeResponse
     */
    employeeName: string;
    /**
     * 社員番号(組織内での管理番号)
     * @type {string}
     * @memberof EmployeeResponse
     */
    employeeNumber?: string;
    /**
     * 年齢
     * @type {number}
     * @memberof EmployeeResponse
     */
    age?: number;
    /**
     * 入社年月日
     * @type {string}
     * @memberof EmployeeResponse
     */
    joinedDate?: string;
    /**
     * 従業員Eメールアドレス
     * @type {string}
     * @memberof EmployeeResponse
     */
    email: string;
}
/**
 * 従業員一覧のレスポンス
 * @export
 * @interface EmployeesResponse
 */
export interface EmployeesResponse {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof EmployeesResponse
     */
    pagination: PaginationResponse;
    /**
     * 
     * @type {Array<EmployeeResponse>}
     * @memberof EmployeesResponse
     */
    items: Array<EmployeeResponse>;
}
/**
 * エラー
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * エラーコード
     * @type {number}
     * @memberof ErrorResponse
     */
    code: number;
    /**
     * エラーメッセージ
     * @type {string}
     * @memberof ErrorResponse
     */
    message: string;
}
/**
 * 主なストレス要因
 * @export
 * @enum {string}
 */
export enum MainStressor {
    BossRelation = 'bossRelation',
    ColleagueRelation = 'colleagueRelation',
    SubordinateRelation = 'subordinateRelation',
    OutsideRelation = 'outsideRelation',
    PersonnelSystem = 'personnelSystem',
    Salary = 'salary',
    CorporateCulture = 'corporateCulture',
    WorkAmount = 'workAmount',
    WorkIrregularity = 'workIrregularity',
    WorkQuality = 'workQuality',
    Welfare = 'welfare',
    IndustryFuture = 'industryFuture',
    PoorHealth = 'poorHealth',
    ParentingCaregiver = 'parentingCaregiver',
    PrivateReason = 'privateReason',
    Nothing = 'nothing',
    ReportDisagreement = 'reportDisagreement'
}

/**
 * 
 * @export
 * @interface PaginationResponse
 */
export interface PaginationResponse {
    /**
     * ページ番号
     * @type {number}
     * @memberof PaginationResponse
     */
    pageNumber: number;
    /**
     * 合計要素数
     * @type {number}
     * @memberof PaginationResponse
     */
    totalElements: number;
    /**
     * 合計ページ数
     * @type {number}
     * @memberof PaginationResponse
     */
    totalPages: number;
}
/**
 * プロフィール変更リクエスト
 * @export
 * @interface ProfileRequest
 */
export interface ProfileRequest {
    /**
     * 登録emailアドレス
     * @type {string}
     * @memberof ProfileRequest
     */
    email: string;
    /**
     * ユーザ氏名
     * @type {string}
     * @memberof ProfileRequest
     */
    name: string;
    /**
     * パスワード
     * @type {string}
     * @memberof ProfileRequest
     */
    password?: string;
}
/**
 * プロフィール詳細
 * @export
 * @interface ProfileResponse
 */
export interface ProfileResponse {
    /**
     * 登録emailアドレス
     * @type {string}
     * @memberof ProfileResponse
     */
    email: string;
    /**
     * ユーザ氏名
     * @type {string}
     * @memberof ProfileResponse
     */
    name: string;
}
/**
 * アンケート設問
 * @export
 * @interface QuestionResponse
 */
export interface QuestionResponse {
    /**
     * アンケート設問ID
     * @type {number}
     * @memberof QuestionResponse
     */
    id: number;
    /**
     * 設問タイトル
     * @type {string}
     * @memberof QuestionResponse
     */
    title: string;
    /**
     * アンケート設問種別
     * @type {string}
     * @memberof QuestionResponse
     */
    type: QuestionResponseTypeEnum;
    /**
     * 設問回答必須有無
     * @type {boolean}
     * @memberof QuestionResponse
     */
    required?: boolean;
    /**
     * アンケート設問回答選択肢
     * @type {Array<ChoiceResponse>}
     * @memberof QuestionResponse
     */
    choices?: Array<ChoiceResponse>;
}

/**
    * @export
    * @enum {string}
    */
export enum QuestionResponseTypeEnum {
    Radio = 'radio',
    Checkbox = 'checkbox',
    Slider = 'slider',
    Text = 'text'
}

/**
 * アンケート配信作成リクエスト
 * @export
 * @interface SurveyDeliveryRequest
 */
export interface SurveyDeliveryRequest {
    /**
     * 配信日時
     * @type {Date}
     * @memberof SurveyDeliveryRequest
     */
    deliveryTimestamp: Date;
    /**
     * 配信状態
     * @type {boolean}
     * @memberof SurveyDeliveryRequest
     */
    deliveryStatus: boolean;
}
/**
 * アンケート配信
 * @export
 * @interface SurveyDeliveryResponse
 */
export interface SurveyDeliveryResponse {
    /**
     * アンケート配信ID
     * @type {number}
     * @memberof SurveyDeliveryResponse
     */
    id: number;
    /**
     * アンケート配信UUID
     * @type {string}
     * @memberof SurveyDeliveryResponse
     */
    uuid: string;
    /**
     * アンケートID
     * @type {number}
     * @memberof SurveyDeliveryResponse
     */
    surveyId: number;
    /**
     * アンケートタイトル
     * @type {string}
     * @memberof SurveyDeliveryResponse
     */
    surveyTitle: string;
    /**
     * 配信日時
     * @type {Date}
     * @memberof SurveyDeliveryResponse
     */
    deliveryTimestamp: Date;
    /**
     * 配信状態
     * @type {boolean}
     * @memberof SurveyDeliveryResponse
     */
    deliveryStatus: boolean;
}
/**
 * アンケート結果詳細
 * @export
 * @interface SurveyResultDetailResponse
 */
export interface SurveyResultDetailResponse {
    /**
     * アンケート結果ID
     * @type {number}
     * @memberof SurveyResultDetailResponse
     */
    id: number;
    /**
     * アンケートタイトル
     * @type {string}
     * @memberof SurveyResultDetailResponse
     */
    title?: string;
    /**
     * 従業員名
     * @type {string}
     * @memberof SurveyResultDetailResponse
     */
    employeeName?: string;
    /**
     * 従業員Eメールアドレス
     * @type {string}
     * @memberof SurveyResultDetailResponse
     */
    employeeEmail?: string;
    /**
     * 設問
     * @type {Array<QuestionResponse>}
     * @memberof SurveyResultDetailResponse
     */
    questions: Array<QuestionResponse>;
    /**
     * 設問回答
     * @type {Array<AnswerResponse>}
     * @memberof SurveyResultDetailResponse
     */
    answers: Array<AnswerResponse>;
    /**
     * 
     * @type {AlertResponse}
     * @memberof SurveyResultDetailResponse
     */
    alert?: AlertResponse;
}
/**
 * アンケート結果
 * @export
 * @interface SurveyResultResponse
 */
export interface SurveyResultResponse {
    /**
     * アンケート結果ID
     * @type {number}
     * @memberof SurveyResultResponse
     */
    id: number;
    /**
     * アンケートタイトル
     * @type {string}
     * @memberof SurveyResultResponse
     */
    title: string;
    /**
     * 従業員名
     * @type {string}
     * @memberof SurveyResultResponse
     */
    employeeName: string;
    /**
     * 従業員Eメールアドレス
     * @type {string}
     * @memberof SurveyResultResponse
     */
    employeeEmail?: string;
    /**
     * 回答開始日時
     * @type {Date}
     * @memberof SurveyResultResponse
     */
    startTimestamp: Date;
    /**
     * 回答完了日時
     * @type {Date}
     * @memberof SurveyResultResponse
     */
    finishTimestamp?: Date;
    /**
     * アラート
     * @type {boolean}
     * @memberof SurveyResultResponse
     */
    alerted: boolean;
}
/**
 * アンケート結果一覧
 * @export
 * @interface SurveyResultsResponse
 */
export interface SurveyResultsResponse {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof SurveyResultsResponse
     */
    pagination: PaginationResponse;
    /**
     * 
     * @type {Array<SurveyResultResponse>}
     * @memberof SurveyResultsResponse
     */
    items: Array<SurveyResultResponse>;
}
/**
 * 面談記録更新リクエスト
 * @export
 * @interface UpdateCounselingRequest
 */
export interface UpdateCounselingRequest {
    /**
     * 面談コメント
     * @type {string}
     * @memberof UpdateCounselingRequest
     */
    comment?: string;
    /**
     * 面談のきっかけ
     * @type {string}
     * @memberof UpdateCounselingRequest
     */
    counselingOpportunity: UpdateCounselingRequestCounselingOpportunityEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateCounselingRequestCounselingOpportunityEnum {
    Questionnaire = 'questionnaire',
    SelfRecommendation = 'selfRecommendation',
    ColleagueRecommendation = 'colleagueRecommendation',
    BossRecommendation = 'bossRecommendation',
    HrRecommendation = 'hrRecommendation',
    Other = 'other'
}


/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アカウントを認証する
         * @param {AccountAuthRequest} accountAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAuthPost(accountAuthRequest: AccountAuthRequest, options: any = {}): RequestArgs {
            // verify required parameter 'accountAuthRequest' is not null or undefined
            if (accountAuthRequest === null || accountAuthRequest === undefined) {
                throw new RequiredError('accountAuthRequest','Required parameter accountAuthRequest was null or undefined when calling accountsAuthPost.');
            }
            const localVarPath = `/accounts/auth`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AccountAuthRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountAuthRequest !== undefined ? accountAuthRequest : {}) : (accountAuthRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アカウントを作成する
         * @param {AccountCreateRequest} accountCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCreatePost(accountCreateRequest: AccountCreateRequest, options: any = {}): RequestArgs {
            // verify required parameter 'accountCreateRequest' is not null or undefined
            if (accountCreateRequest === null || accountCreateRequest === undefined) {
                throw new RequiredError('accountCreateRequest','Required parameter accountCreateRequest was null or undefined when calling accountsCreatePost.');
            }
            const localVarPath = `/accounts/create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AccountCreateRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountCreateRequest !== undefined ? accountCreateRequest : {}) : (accountCreateRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アカウントが存在するか確認する
         * @param {string} email メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsHead(email: string, options: any = {}): RequestArgs {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling accountsHead.');
            }
            const localVarPath = `/accounts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 仮登録アカウントを正式に登録する
         * @param {AccountRegisterRequest} accountRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRegisterPost(accountRegisterRequest: AccountRegisterRequest, options: any = {}): RequestArgs {
            // verify required parameter 'accountRegisterRequest' is not null or undefined
            if (accountRegisterRequest === null || accountRegisterRequest === undefined) {
                throw new RequiredError('accountRegisterRequest','Required parameter accountRegisterRequest was null or undefined when calling accountsRegisterPost.');
            }
            const localVarPath = `/accounts/register`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AccountRegisterRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountRegisterRequest !== undefined ? accountRegisterRequest : {}) : (accountRegisterRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アカウントを認証する
         * @param {AccountAuthRequest} accountAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAuthPost(accountAuthRequest: AccountAuthRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountsAuthPost(accountAuthRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アカウントを作成する
         * @param {AccountCreateRequest} accountCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCreatePost(accountCreateRequest: AccountCreateRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountsCreatePost(accountCreateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アカウントが存在するか確認する
         * @param {string} email メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsHead(email: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountsHead(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 仮登録アカウントを正式に登録する
         * @param {AccountRegisterRequest} accountRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRegisterPost(accountRegisterRequest: AccountRegisterRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountsRegisterPost(accountRegisterRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary アカウントを認証する
         * @param {AccountAuthRequest} accountAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAuthPost(accountAuthRequest: AccountAuthRequest, options?: any) {
            return AccountApiFp(configuration).accountsAuthPost(accountAuthRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary アカウントを作成する
         * @param {AccountCreateRequest} accountCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCreatePost(accountCreateRequest: AccountCreateRequest, options?: any) {
            return AccountApiFp(configuration).accountsCreatePost(accountCreateRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary アカウントが存在するか確認する
         * @param {string} email メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsHead(email: string, options?: any) {
            return AccountApiFp(configuration).accountsHead(email, options)(axios, basePath);
        },
        /**
         * 
         * @summary 仮登録アカウントを正式に登録する
         * @param {AccountRegisterRequest} accountRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRegisterPost(accountRegisterRequest: AccountRegisterRequest, options?: any) {
            return AccountApiFp(configuration).accountsRegisterPost(accountRegisterRequest, options)(axios, basePath);
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary アカウントを認証する
     * @param {AccountAuthRequest} accountAuthRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountsAuthPost(accountAuthRequest: AccountAuthRequest, options?: any) {
        return AccountApiFp(this.configuration).accountsAuthPost(accountAuthRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary アカウントを作成する
     * @param {AccountCreateRequest} accountCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountsCreatePost(accountCreateRequest: AccountCreateRequest, options?: any) {
        return AccountApiFp(this.configuration).accountsCreatePost(accountCreateRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary アカウントが存在するか確認する
     * @param {string} email メールアドレス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountsHead(email: string, options?: any) {
        return AccountApiFp(this.configuration).accountsHead(email, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 仮登録アカウントを正式に登録する
     * @param {AccountRegisterRequest} accountRegisterRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountsRegisterPost(accountRegisterRequest: AccountRegisterRequest, options?: any) {
        return AccountApiFp(this.configuration).accountsRegisterPost(accountRegisterRequest, options)(this.axios, this.basePath);
    }

}


/**
 * AlertApi - axios parameter creator
 * @export
 */
export const AlertApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アラート一覧情報を取得する
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertsGet(page?: number, limit?: number, options: any = {}): RequestArgs {
            const localVarPath = `/alerts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertApi - functional programming interface
 * @export
 */
export const AlertApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アラート一覧情報を取得する
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertsGet(page?: number, limit?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertsResponse> {
            const localVarAxiosArgs = AlertApiAxiosParamCreator(configuration).alertsGet(page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AlertApi - factory interface
 * @export
 */
export const AlertApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary アラート一覧情報を取得する
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertsGet(page?: number, limit?: number, options?: any) {
            return AlertApiFp(configuration).alertsGet(page, limit, options)(axios, basePath);
        },
    };
};

/**
 * AlertApi - object-oriented interface
 * @export
 * @class AlertApi
 * @extends {BaseAPI}
 */
export class AlertApi extends BaseAPI {
    /**
     * 
     * @summary アラート一覧情報を取得する
     * @param {number} [page] page
     * @param {number} [limit] limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public alertsGet(page?: number, limit?: number, options?: any) {
        return AlertApiFp(this.configuration).alertsGet(page, limit, options)(this.axios, this.basePath);
    }

}


/**
 * ContractApi - axios parameter creator
 * @export
 */
export const ContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 今期の契約情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentContractGet(options: any = {}): RequestArgs {
            const localVarPath = `/current_contract`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractApi - functional programming interface
 * @export
 */
export const ContractApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 今期の契約情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentContractGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentContractResponse> {
            const localVarAxiosArgs = ContractApiAxiosParamCreator(configuration).currentContractGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContractApi - factory interface
 * @export
 */
export const ContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 今期の契約情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentContractGet(options?: any) {
            return ContractApiFp(configuration).currentContractGet(options)(axios, basePath);
        },
    };
};

/**
 * ContractApi - object-oriented interface
 * @export
 * @class ContractApi
 * @extends {BaseAPI}
 */
export class ContractApi extends BaseAPI {
    /**
     * 
     * @summary 今期の契約情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public currentContractGet(options?: any) {
        return ContractApiFp(this.configuration).currentContractGet(options)(this.axios, this.basePath);
    }

}


/**
 * CounselingApi - axios parameter creator
 * @export
 */
export const CounselingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 面談調整を一括作成する
         * @param {BulkCreateCounselingRequest} bulkCreateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsBulkCreatePost(bulkCreateCounselingRequest: BulkCreateCounselingRequest, options: any = {}): RequestArgs {
            // verify required parameter 'bulkCreateCounselingRequest' is not null or undefined
            if (bulkCreateCounselingRequest === null || bulkCreateCounselingRequest === undefined) {
                throw new RequiredError('bulkCreateCounselingRequest','Required parameter bulkCreateCounselingRequest was null or undefined when calling counselingsBulkCreatePost.');
            }
            const localVarPath = `/counselings/bulk-create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"BulkCreateCounselingRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bulkCreateCounselingRequest !== undefined ? bulkCreateCounselingRequest : {}) : (bulkCreateCounselingRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 面談記録を取り消す
         * @param {number} counselingReservationId 面談予約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsCounselingReservationIdCancelPut(counselingReservationId: number, options: any = {}): RequestArgs {
            // verify required parameter 'counselingReservationId' is not null or undefined
            if (counselingReservationId === null || counselingReservationId === undefined) {
                throw new RequiredError('counselingReservationId','Required parameter counselingReservationId was null or undefined when calling counselingsCounselingReservationIdCancelPut.');
            }
            const localVarPath = `/counselings/{counseling_reservation_id}/cancel`
                .replace(`{${"counseling_reservation_id"}}`, encodeURIComponent(String(counselingReservationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 面談記録を取得する
         * @param {number} counselingReservationId 面談予約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsCounselingReservationIdGet(counselingReservationId: number, options: any = {}): RequestArgs {
            // verify required parameter 'counselingReservationId' is not null or undefined
            if (counselingReservationId === null || counselingReservationId === undefined) {
                throw new RequiredError('counselingReservationId','Required parameter counselingReservationId was null or undefined when calling counselingsCounselingReservationIdGet.');
            }
            const localVarPath = `/counselings/{counseling_reservation_id}`
                .replace(`{${"counseling_reservation_id"}}`, encodeURIComponent(String(counselingReservationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 面談記録を更新する
         * @param {number} counselingReservationId 面談予約ID
         * @param {UpdateCounselingRequest} updateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsCounselingReservationIdPut(counselingReservationId: number, updateCounselingRequest: UpdateCounselingRequest, options: any = {}): RequestArgs {
            // verify required parameter 'counselingReservationId' is not null or undefined
            if (counselingReservationId === null || counselingReservationId === undefined) {
                throw new RequiredError('counselingReservationId','Required parameter counselingReservationId was null or undefined when calling counselingsCounselingReservationIdPut.');
            }
            // verify required parameter 'updateCounselingRequest' is not null or undefined
            if (updateCounselingRequest === null || updateCounselingRequest === undefined) {
                throw new RequiredError('updateCounselingRequest','Required parameter updateCounselingRequest was null or undefined when calling counselingsCounselingReservationIdPut.');
            }
            const localVarPath = `/counselings/{counseling_reservation_id}`
                .replace(`{${"counseling_reservation_id"}}`, encodeURIComponent(String(counselingReservationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"UpdateCounselingRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateCounselingRequest !== undefined ? updateCounselingRequest : {}) : (updateCounselingRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 面談フォームのパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsFormGet(options: any = {}): RequestArgs {
            const localVarPath = `/counselings/form`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary すべての面談記録を取得する
         * @param {number} [employeeId] 従業員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsGet(employeeId?: number, options: any = {}): RequestArgs {
            const localVarPath = `/counselings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employeeId'] = employeeId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 面談記録を作成する
         * @param {CreateCounselingRequest} createCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsPost(createCounselingRequest: CreateCounselingRequest, options: any = {}): RequestArgs {
            // verify required parameter 'createCounselingRequest' is not null or undefined
            if (createCounselingRequest === null || createCounselingRequest === undefined) {
                throw new RequiredError('createCounselingRequest','Required parameter createCounselingRequest was null or undefined when calling counselingsPost.');
            }
            const localVarPath = `/counselings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"CreateCounselingRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createCounselingRequest !== undefined ? createCounselingRequest : {}) : (createCounselingRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CounselingApi - functional programming interface
 * @export
 */
export const CounselingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 面談調整を一括作成する
         * @param {BulkCreateCounselingRequest} bulkCreateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsBulkCreatePost(bulkCreateCounselingRequest: BulkCreateCounselingRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).counselingsBulkCreatePost(bulkCreateCounselingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 面談記録を取り消す
         * @param {number} counselingReservationId 面談予約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsCounselingReservationIdCancelPut(counselingReservationId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).counselingsCounselingReservationIdCancelPut(counselingReservationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 面談記録を取得する
         * @param {number} counselingReservationId 面談予約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsCounselingReservationIdGet(counselingReservationId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CounselingDetailResponse> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).counselingsCounselingReservationIdGet(counselingReservationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 面談記録を更新する
         * @param {number} counselingReservationId 面談予約ID
         * @param {UpdateCounselingRequest} updateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsCounselingReservationIdPut(counselingReservationId: number, updateCounselingRequest: UpdateCounselingRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).counselingsCounselingReservationIdPut(counselingReservationId, updateCounselingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 面談フォームのパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsFormGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CounselingFormResponse> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).counselingsFormGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary すべての面談記録を取得する
         * @param {number} [employeeId] 従業員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsGet(employeeId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CounselingResponse>> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).counselingsGet(employeeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 面談記録を作成する
         * @param {CreateCounselingRequest} createCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsPost(createCounselingRequest: CreateCounselingRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).counselingsPost(createCounselingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CounselingApi - factory interface
 * @export
 */
export const CounselingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 面談調整を一括作成する
         * @param {BulkCreateCounselingRequest} bulkCreateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsBulkCreatePost(bulkCreateCounselingRequest: BulkCreateCounselingRequest, options?: any) {
            return CounselingApiFp(configuration).counselingsBulkCreatePost(bulkCreateCounselingRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary 面談記録を取り消す
         * @param {number} counselingReservationId 面談予約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsCounselingReservationIdCancelPut(counselingReservationId: number, options?: any) {
            return CounselingApiFp(configuration).counselingsCounselingReservationIdCancelPut(counselingReservationId, options)(axios, basePath);
        },
        /**
         * 
         * @summary 面談記録を取得する
         * @param {number} counselingReservationId 面談予約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsCounselingReservationIdGet(counselingReservationId: number, options?: any) {
            return CounselingApiFp(configuration).counselingsCounselingReservationIdGet(counselingReservationId, options)(axios, basePath);
        },
        /**
         * 
         * @summary 面談記録を更新する
         * @param {number} counselingReservationId 面談予約ID
         * @param {UpdateCounselingRequest} updateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsCounselingReservationIdPut(counselingReservationId: number, updateCounselingRequest: UpdateCounselingRequest, options?: any) {
            return CounselingApiFp(configuration).counselingsCounselingReservationIdPut(counselingReservationId, updateCounselingRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary 面談フォームのパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsFormGet(options?: any) {
            return CounselingApiFp(configuration).counselingsFormGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary すべての面談記録を取得する
         * @param {number} [employeeId] 従業員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsGet(employeeId?: number, options?: any) {
            return CounselingApiFp(configuration).counselingsGet(employeeId, options)(axios, basePath);
        },
        /**
         * 
         * @summary 面談記録を作成する
         * @param {CreateCounselingRequest} createCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsPost(createCounselingRequest: CreateCounselingRequest, options?: any) {
            return CounselingApiFp(configuration).counselingsPost(createCounselingRequest, options)(axios, basePath);
        },
    };
};

/**
 * CounselingApi - object-oriented interface
 * @export
 * @class CounselingApi
 * @extends {BaseAPI}
 */
export class CounselingApi extends BaseAPI {
    /**
     * 
     * @summary 面談調整を一括作成する
     * @param {BulkCreateCounselingRequest} bulkCreateCounselingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public counselingsBulkCreatePost(bulkCreateCounselingRequest: BulkCreateCounselingRequest, options?: any) {
        return CounselingApiFp(this.configuration).counselingsBulkCreatePost(bulkCreateCounselingRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 面談記録を取り消す
     * @param {number} counselingReservationId 面談予約ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public counselingsCounselingReservationIdCancelPut(counselingReservationId: number, options?: any) {
        return CounselingApiFp(this.configuration).counselingsCounselingReservationIdCancelPut(counselingReservationId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 面談記録を取得する
     * @param {number} counselingReservationId 面談予約ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public counselingsCounselingReservationIdGet(counselingReservationId: number, options?: any) {
        return CounselingApiFp(this.configuration).counselingsCounselingReservationIdGet(counselingReservationId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 面談記録を更新する
     * @param {number} counselingReservationId 面談予約ID
     * @param {UpdateCounselingRequest} updateCounselingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public counselingsCounselingReservationIdPut(counselingReservationId: number, updateCounselingRequest: UpdateCounselingRequest, options?: any) {
        return CounselingApiFp(this.configuration).counselingsCounselingReservationIdPut(counselingReservationId, updateCounselingRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 面談フォームのパラメータ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public counselingsFormGet(options?: any) {
        return CounselingApiFp(this.configuration).counselingsFormGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary すべての面談記録を取得する
     * @param {number} [employeeId] 従業員ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public counselingsGet(employeeId?: number, options?: any) {
        return CounselingApiFp(this.configuration).counselingsGet(employeeId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 面談記録を作成する
     * @param {CreateCounselingRequest} createCounselingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public counselingsPost(createCounselingRequest: CreateCounselingRequest, options?: any) {
        return CounselingApiFp(this.configuration).counselingsPost(createCounselingRequest, options)(this.axios, this.basePath);
    }

}


/**
 * EmployeeApi - axios parameter creator
 * @export
 */
export const EmployeeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 従業員情報を取得する
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesGet(page?: number, limit?: number, options: any = {}): RequestArgs {
            const localVarPath = `/employees`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 従業員情報を取得する
         * @param {number} id 従業員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesIdGet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling employeesIdGet.');
            }
            const localVarPath = `/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 従業員情報を更新する
         * @param {number} id 従業員ID
         * @param {EmployeeRequest} employeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesIdPut(id: number, employeeRequest: EmployeeRequest, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling employeesIdPut.');
            }
            // verify required parameter 'employeeRequest' is not null or undefined
            if (employeeRequest === null || employeeRequest === undefined) {
                throw new RequiredError('employeeRequest','Required parameter employeeRequest was null or undefined when calling employeesIdPut.');
            }
            const localVarPath = `/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"EmployeeRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(employeeRequest !== undefined ? employeeRequest : {}) : (employeeRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 従業員情報を作成する
         * @param {EmployeeRequest} employeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesPost(employeeRequest: EmployeeRequest, options: any = {}): RequestArgs {
            // verify required parameter 'employeeRequest' is not null or undefined
            if (employeeRequest === null || employeeRequest === undefined) {
                throw new RequiredError('employeeRequest','Required parameter employeeRequest was null or undefined when calling employeesPost.');
            }
            const localVarPath = `/employees`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"EmployeeRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(employeeRequest !== undefined ? employeeRequest : {}) : (employeeRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 最新のアンケート配信で未回答の従業員一覧を取得する
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesUnansweredGet(page?: number, limit?: number, options: any = {}): RequestArgs {
            const localVarPath = `/employees/unanswered`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeApi - functional programming interface
 * @export
 */
export const EmployeeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 従業員情報を取得する
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesGet(page?: number, limit?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeesResponse> {
            const localVarAxiosArgs = EmployeeApiAxiosParamCreator(configuration).employeesGet(page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 従業員情報を取得する
         * @param {number} id 従業員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesIdGet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse> {
            const localVarAxiosArgs = EmployeeApiAxiosParamCreator(configuration).employeesIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 従業員情報を更新する
         * @param {number} id 従業員ID
         * @param {EmployeeRequest} employeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesIdPut(id: number, employeeRequest: EmployeeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse> {
            const localVarAxiosArgs = EmployeeApiAxiosParamCreator(configuration).employeesIdPut(id, employeeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 従業員情報を作成する
         * @param {EmployeeRequest} employeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesPost(employeeRequest: EmployeeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse> {
            const localVarAxiosArgs = EmployeeApiAxiosParamCreator(configuration).employeesPost(employeeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 最新のアンケート配信で未回答の従業員一覧を取得する
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesUnansweredGet(page?: number, limit?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeesResponse> {
            const localVarAxiosArgs = EmployeeApiAxiosParamCreator(configuration).employeesUnansweredGet(page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EmployeeApi - factory interface
 * @export
 */
export const EmployeeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 従業員情報を取得する
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesGet(page?: number, limit?: number, options?: any) {
            return EmployeeApiFp(configuration).employeesGet(page, limit, options)(axios, basePath);
        },
        /**
         * 
         * @summary 従業員情報を取得する
         * @param {number} id 従業員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesIdGet(id: number, options?: any) {
            return EmployeeApiFp(configuration).employeesIdGet(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary 従業員情報を更新する
         * @param {number} id 従業員ID
         * @param {EmployeeRequest} employeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesIdPut(id: number, employeeRequest: EmployeeRequest, options?: any) {
            return EmployeeApiFp(configuration).employeesIdPut(id, employeeRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary 従業員情報を作成する
         * @param {EmployeeRequest} employeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesPost(employeeRequest: EmployeeRequest, options?: any) {
            return EmployeeApiFp(configuration).employeesPost(employeeRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary 最新のアンケート配信で未回答の従業員一覧を取得する
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesUnansweredGet(page?: number, limit?: number, options?: any) {
            return EmployeeApiFp(configuration).employeesUnansweredGet(page, limit, options)(axios, basePath);
        },
    };
};

/**
 * EmployeeApi - object-oriented interface
 * @export
 * @class EmployeeApi
 * @extends {BaseAPI}
 */
export class EmployeeApi extends BaseAPI {
    /**
     * 
     * @summary 従業員情報を取得する
     * @param {number} [page] page
     * @param {number} [limit] limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeesGet(page?: number, limit?: number, options?: any) {
        return EmployeeApiFp(this.configuration).employeesGet(page, limit, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 従業員情報を取得する
     * @param {number} id 従業員ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeesIdGet(id: number, options?: any) {
        return EmployeeApiFp(this.configuration).employeesIdGet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 従業員情報を更新する
     * @param {number} id 従業員ID
     * @param {EmployeeRequest} employeeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeesIdPut(id: number, employeeRequest: EmployeeRequest, options?: any) {
        return EmployeeApiFp(this.configuration).employeesIdPut(id, employeeRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 従業員情報を作成する
     * @param {EmployeeRequest} employeeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeesPost(employeeRequest: EmployeeRequest, options?: any) {
        return EmployeeApiFp(this.configuration).employeesPost(employeeRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 最新のアンケート配信で未回答の従業員一覧を取得する
     * @param {number} [page] page
     * @param {number} [limit] limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeesUnansweredGet(page?: number, limit?: number, options?: any) {
        return EmployeeApiFp(this.configuration).employeesUnansweredGet(page, limit, options)(this.axios, this.basePath);
    }

}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary プロフィール情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGet(options: any = {}): RequestArgs {
            const localVarPath = `/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary プロフィール情報を更新する
         * @param {ProfileRequest} [profileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilePost(profileRequest?: ProfileRequest, options: any = {}): RequestArgs {
            const localVarPath = `/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ProfileRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(profileRequest !== undefined ? profileRequest : {}) : (profileRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary プロフィール情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileResponse> {
            const localVarAxiosArgs = ProfileApiAxiosParamCreator(configuration).profileGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary プロフィール情報を更新する
         * @param {ProfileRequest} [profileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilePost(profileRequest?: ProfileRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileResponse> {
            const localVarAxiosArgs = ProfileApiAxiosParamCreator(configuration).profilePost(profileRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary プロフィール情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGet(options?: any) {
            return ProfileApiFp(configuration).profileGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary プロフィール情報を更新する
         * @param {ProfileRequest} [profileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilePost(profileRequest?: ProfileRequest, options?: any) {
            return ProfileApiFp(configuration).profilePost(profileRequest, options)(axios, basePath);
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @summary プロフィール情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileGet(options?: any) {
        return ProfileApiFp(this.configuration).profileGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary プロフィール情報を更新する
     * @param {ProfileRequest} [profileRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profilePost(profileRequest?: ProfileRequest, options?: any) {
        return ProfileApiFp(this.configuration).profilePost(profileRequest, options)(this.axios, this.basePath);
    }

}


/**
 * SurveyDeliveryApi - axios parameter creator
 * @export
 */
export const SurveyDeliveryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary すべてのアンケート配信を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDeliveriesGet(options: any = {}): RequestArgs {
            const localVarPath = `/survey-deliveries`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アンケート配信を取得する
         * @param {number} id アンケート配信ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDeliveriesIdGet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyDeliveriesIdGet.');
            }
            const localVarPath = `/survey-deliveries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アンケート配信を更新する
         * @param {number} id アンケート配信ID
         * @param {SurveyDeliveryRequest} surveyDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDeliveriesIdPut(id: number, surveyDeliveryRequest: SurveyDeliveryRequest, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyDeliveriesIdPut.');
            }
            // verify required parameter 'surveyDeliveryRequest' is not null or undefined
            if (surveyDeliveryRequest === null || surveyDeliveryRequest === undefined) {
                throw new RequiredError('surveyDeliveryRequest','Required parameter surveyDeliveryRequest was null or undefined when calling surveyDeliveriesIdPut.');
            }
            const localVarPath = `/survey-deliveries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"SurveyDeliveryRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(surveyDeliveryRequest !== undefined ? surveyDeliveryRequest : {}) : (surveyDeliveryRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アンケート配信を作成する
         * @param {SurveyDeliveryRequest} surveyDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDeliveriesPost(surveyDeliveryRequest: SurveyDeliveryRequest, options: any = {}): RequestArgs {
            // verify required parameter 'surveyDeliveryRequest' is not null or undefined
            if (surveyDeliveryRequest === null || surveyDeliveryRequest === undefined) {
                throw new RequiredError('surveyDeliveryRequest','Required parameter surveyDeliveryRequest was null or undefined when calling surveyDeliveriesPost.');
            }
            const localVarPath = `/survey-deliveries`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"SurveyDeliveryRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(surveyDeliveryRequest !== undefined ? surveyDeliveryRequest : {}) : (surveyDeliveryRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyDeliveryApi - functional programming interface
 * @export
 */
export const SurveyDeliveryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary すべてのアンケート配信を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDeliveriesGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyDeliveryResponse>> {
            const localVarAxiosArgs = SurveyDeliveryApiAxiosParamCreator(configuration).surveyDeliveriesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アンケート配信を取得する
         * @param {number} id アンケート配信ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDeliveriesIdGet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyDeliveryResponse> {
            const localVarAxiosArgs = SurveyDeliveryApiAxiosParamCreator(configuration).surveyDeliveriesIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アンケート配信を更新する
         * @param {number} id アンケート配信ID
         * @param {SurveyDeliveryRequest} surveyDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDeliveriesIdPut(id: number, surveyDeliveryRequest: SurveyDeliveryRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SurveyDeliveryApiAxiosParamCreator(configuration).surveyDeliveriesIdPut(id, surveyDeliveryRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アンケート配信を作成する
         * @param {SurveyDeliveryRequest} surveyDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDeliveriesPost(surveyDeliveryRequest: SurveyDeliveryRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SurveyDeliveryApiAxiosParamCreator(configuration).surveyDeliveriesPost(surveyDeliveryRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SurveyDeliveryApi - factory interface
 * @export
 */
export const SurveyDeliveryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary すべてのアンケート配信を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDeliveriesGet(options?: any) {
            return SurveyDeliveryApiFp(configuration).surveyDeliveriesGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary アンケート配信を取得する
         * @param {number} id アンケート配信ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDeliveriesIdGet(id: number, options?: any) {
            return SurveyDeliveryApiFp(configuration).surveyDeliveriesIdGet(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary アンケート配信を更新する
         * @param {number} id アンケート配信ID
         * @param {SurveyDeliveryRequest} surveyDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDeliveriesIdPut(id: number, surveyDeliveryRequest: SurveyDeliveryRequest, options?: any) {
            return SurveyDeliveryApiFp(configuration).surveyDeliveriesIdPut(id, surveyDeliveryRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary アンケート配信を作成する
         * @param {SurveyDeliveryRequest} surveyDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDeliveriesPost(surveyDeliveryRequest: SurveyDeliveryRequest, options?: any) {
            return SurveyDeliveryApiFp(configuration).surveyDeliveriesPost(surveyDeliveryRequest, options)(axios, basePath);
        },
    };
};

/**
 * SurveyDeliveryApi - object-oriented interface
 * @export
 * @class SurveyDeliveryApi
 * @extends {BaseAPI}
 */
export class SurveyDeliveryApi extends BaseAPI {
    /**
     * 
     * @summary すべてのアンケート配信を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyDeliveryApi
     */
    public surveyDeliveriesGet(options?: any) {
        return SurveyDeliveryApiFp(this.configuration).surveyDeliveriesGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary アンケート配信を取得する
     * @param {number} id アンケート配信ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyDeliveryApi
     */
    public surveyDeliveriesIdGet(id: number, options?: any) {
        return SurveyDeliveryApiFp(this.configuration).surveyDeliveriesIdGet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary アンケート配信を更新する
     * @param {number} id アンケート配信ID
     * @param {SurveyDeliveryRequest} surveyDeliveryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyDeliveryApi
     */
    public surveyDeliveriesIdPut(id: number, surveyDeliveryRequest: SurveyDeliveryRequest, options?: any) {
        return SurveyDeliveryApiFp(this.configuration).surveyDeliveriesIdPut(id, surveyDeliveryRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary アンケート配信を作成する
     * @param {SurveyDeliveryRequest} surveyDeliveryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyDeliveryApi
     */
    public surveyDeliveriesPost(surveyDeliveryRequest: SurveyDeliveryRequest, options?: any) {
        return SurveyDeliveryApiFp(this.configuration).surveyDeliveriesPost(surveyDeliveryRequest, options)(this.axios, this.basePath);
    }

}


/**
 * SurveyResultApi - axios parameter creator
 * @export
 */
export const SurveyResultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アンケート結果をCSVで取得する
         * @param {number} surveyDeliveryId アンケート配信ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsCsvGet(surveyDeliveryId: number, options: any = {}): RequestArgs {
            // verify required parameter 'surveyDeliveryId' is not null or undefined
            if (surveyDeliveryId === null || surveyDeliveryId === undefined) {
                throw new RequiredError('surveyDeliveryId','Required parameter surveyDeliveryId was null or undefined when calling surveyResultsCsvGet.');
            }
            const localVarPath = `/survey-results/csv`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (surveyDeliveryId !== undefined) {
                localVarQueryParameter['surveyDeliveryId'] = surveyDeliveryId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アンケート結果を取得する
         * @param {number} [employeeId] 従業員ID
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {boolean} [orderByAlert] アラート順にソート
         * @param {number} [surveyDeliveryId] アンケート配信ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsGet(employeeId?: number, page?: number, limit?: number, orderByAlert?: boolean, surveyDeliveryId?: number, options: any = {}): RequestArgs {
            const localVarPath = `/survey-results`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employeeId'] = employeeId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderByAlert !== undefined) {
                localVarQueryParameter['orderByAlert'] = orderByAlert;
            }

            if (surveyDeliveryId !== undefined) {
                localVarQueryParameter['surveyDeliveryId'] = surveyDeliveryId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アンケート結果詳細を取得する
         * @param {number} id アンケート結果ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsIdGet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyResultsIdGet.');
            }
            const localVarPath = `/survey-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyResultApi - functional programming interface
 * @export
 */
export const SurveyResultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アンケート結果をCSVで取得する
         * @param {number} surveyDeliveryId アンケート配信ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsCsvGet(surveyDeliveryId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = SurveyResultApiAxiosParamCreator(configuration).surveyResultsCsvGet(surveyDeliveryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アンケート結果を取得する
         * @param {number} [employeeId] 従業員ID
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {boolean} [orderByAlert] アラート順にソート
         * @param {number} [surveyDeliveryId] アンケート配信ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsGet(employeeId?: number, page?: number, limit?: number, orderByAlert?: boolean, surveyDeliveryId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResultsResponse> {
            const localVarAxiosArgs = SurveyResultApiAxiosParamCreator(configuration).surveyResultsGet(employeeId, page, limit, orderByAlert, surveyDeliveryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アンケート結果詳細を取得する
         * @param {number} id アンケート結果ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsIdGet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResultDetailResponse> {
            const localVarAxiosArgs = SurveyResultApiAxiosParamCreator(configuration).surveyResultsIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SurveyResultApi - factory interface
 * @export
 */
export const SurveyResultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary アンケート結果をCSVで取得する
         * @param {number} surveyDeliveryId アンケート配信ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsCsvGet(surveyDeliveryId: number, options?: any) {
            return SurveyResultApiFp(configuration).surveyResultsCsvGet(surveyDeliveryId, options)(axios, basePath);
        },
        /**
         * 
         * @summary アンケート結果を取得する
         * @param {number} [employeeId] 従業員ID
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {boolean} [orderByAlert] アラート順にソート
         * @param {number} [surveyDeliveryId] アンケート配信ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsGet(employeeId?: number, page?: number, limit?: number, orderByAlert?: boolean, surveyDeliveryId?: number, options?: any) {
            return SurveyResultApiFp(configuration).surveyResultsGet(employeeId, page, limit, orderByAlert, surveyDeliveryId, options)(axios, basePath);
        },
        /**
         * 
         * @summary アンケート結果詳細を取得する
         * @param {number} id アンケート結果ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsIdGet(id: number, options?: any) {
            return SurveyResultApiFp(configuration).surveyResultsIdGet(id, options)(axios, basePath);
        },
    };
};

/**
 * SurveyResultApi - object-oriented interface
 * @export
 * @class SurveyResultApi
 * @extends {BaseAPI}
 */
export class SurveyResultApi extends BaseAPI {
    /**
     * 
     * @summary アンケート結果をCSVで取得する
     * @param {number} surveyDeliveryId アンケート配信ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyResultApi
     */
    public surveyResultsCsvGet(surveyDeliveryId: number, options?: any) {
        return SurveyResultApiFp(this.configuration).surveyResultsCsvGet(surveyDeliveryId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary アンケート結果を取得する
     * @param {number} [employeeId] 従業員ID
     * @param {number} [page] page
     * @param {number} [limit] limit
     * @param {boolean} [orderByAlert] アラート順にソート
     * @param {number} [surveyDeliveryId] アンケート配信ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyResultApi
     */
    public surveyResultsGet(employeeId?: number, page?: number, limit?: number, orderByAlert?: boolean, surveyDeliveryId?: number, options?: any) {
        return SurveyResultApiFp(this.configuration).surveyResultsGet(employeeId, page, limit, orderByAlert, surveyDeliveryId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary アンケート結果詳細を取得する
     * @param {number} id アンケート結果ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyResultApi
     */
    public surveyResultsIdGet(id: number, options?: any) {
        return SurveyResultApiFp(this.configuration).surveyResultsIdGet(id, options)(this.axios, this.basePath);
    }

}


