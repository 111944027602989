import React, { useState, useEffect } from 'react';
import {
  Typography, Button, CircularProgress, Box, Paper,
} from '@material-ui/core';
import useReactRouter from 'use-react-router';
import { withAuth, AuthedComponentProps } from '../../auth/Authenticated';
import { SideMenuLayout } from '../../layouts';
import { usePrivateApi } from '../../api/useApi';
import { SurveyResultDetailResponse, SurveyResultApi } from '../../generated';
import { QuestionResult } from '../../components/survey_result/QuestionResult';

const Content = ({ session }: AuthedComponentProps) => {
  const [loading, setLoading] = useState(false);
  const [surveyResult, setSurveyResult] = useState<SurveyResultDetailResponse>();
  const { match, history } = useReactRouter<{ id: string }>();
  const { api } = usePrivateApi(SurveyResultApi);

  useEffect(() => {
    setLoading(true);
    api.surveyResultsIdGet(Number(match.params.id))
      .then(res => {
        setSurveyResult(res.data);
      }).finally(() => {
        setLoading(false);
      });
  }, [session, match.params.id]);

  const onClickCounseling = () => {
    if (surveyResult == null || surveyResult.alert == null) return;
    history.push(`/counselings/new?surveyResultId=${surveyResult.id}`);
  };

  if (loading) {
    return <CircularProgress />;
  }
  if (surveyResult) {
    const questions = surveyResult.questions.map(q => {
      const choices = (q.choices || []).map(c => ({
        id: c.id,
        title: c.title,
        value: c.value,
      }));
      return {
        id: q.id,
        title: q.title,
        type: q.type as 'radio' | 'text' | 'checkbox',
        choices,
      };
    });
    return (
      <>

        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h4" component="h1">
              アンケート結果
            </Typography>
          </Box>
          <Box>
            <Button variant="contained" color="primary" onClick={onClickCounseling} disabled={surveyResult && surveyResult.alert == null}>面談記録を作成する</Button>
          </Box>
        </Box>
        <Paper>
          <Box p={2}>
            <Typography>
              {`従業員名: ${surveyResult.employeeName}`}
            </Typography>
            <Typography>
              {`従業員Eメールアドレス: ${surveyResult.employeeEmail}`}
            </Typography>

            {surveyResult.alert && surveyResult.alert.labels.filter((x, i, self) => self.indexOf(x) === i).map(label => <Typography color="error">{label}</Typography>)}

            {questions.map((q, index) => (
              <QuestionResult
                index={index}
                question={q}
                answers={surveyResult.answers.filter(a => a.questionId === q.id)}
              />
            ))}
          </Box>
        </Paper>
      </>
    );
  }
  return <p>404 not found</p>;
};

export const SurveyResultDetailPage = SideMenuLayout(withAuth(Content));
