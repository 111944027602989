import 'react-app-polyfill/ie9';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import firebase from 'firebase';
import * as Sentry from '@sentry/browser';
import './index.css';
import { CssBaseline } from '@material-ui/core';
import { config } from './auth/FirebaseConfig';
import { AppProvider } from './foundation/AppProvider';
import {
  LoginPage,
  CreateAccountPage,
  RegisterAccountPage,
} from './pages/auth';
import { NotVerifiedPage } from './pages/NotVerifiedPage';
import { TermsPage } from './pages/TermsPage';
import {
  SurveyDeliveryEditPage,
  SurveyDeliveryIndexPage,
  SurveyDeliveryDetailPage,
} from './pages/survey_delivery';
import {
  CounselingIndexPage,
  CounselingCreatePage,
  CounselingBulkCreatePage,
  CounselingEditPage,
  CounselingDetailPage,
} from './pages/counseling';
import {
  EmployeeIndexPage,
  EmployeeCreatePage,
  EmployeeDetailPage,
} from './pages/employee';
import { SurveyResultIndexPage, SurveyResultDetailPage } from './pages/survey_result';
import { NotFoundPage } from './pages/NotFoundPage';
import { withAuth, withoutAuth } from './auth/Authenticated';
import { theme } from './theme';
import { PasswordResetPage } from './pages/auth/PasswordResetPage';
import { DashboardPage } from './pages/dashboard';

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
Sentry.configureScope(scope => {
  scope.setTag('module', 'organization');
  scope.setUser({ id: 'guest' });
});
firebase.initializeApp(config);

ReactDOM.render(
  <AppProvider>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          <Route exact path="/" component={withAuth(DashboardPage)} />
          <Route exact path="/auth/signin" component={withoutAuth(LoginPage)} />
          <Route path="/auth/register" component={withoutAuth(RegisterAccountPage)} />
          <Route path="/auth/register-organization" component={withoutAuth(CreateAccountPage)} />
          <Route exact path="/auth/password-reset" component={withoutAuth(PasswordResetPage)} />
          <Route exact path="/auth/not-verified" component={withAuth(NotVerifiedPage)} />
          <Route exact path="/about/terms" component={TermsPage} />
          <Route exact path="/employees/" component={withAuth(EmployeeIndexPage)} />
          <Route exact path="/employees/new" component={withAuth(EmployeeCreatePage)} />
          <Route exact path="/employees/:id(\d+)" component={withAuth(EmployeeDetailPage)} />
          <Route exact path="/employees/:id(\d+)/edit" component={withAuth(EmployeeCreatePage)} />
          <Route exact path="/survey-deliveries" component={withAuth(SurveyDeliveryIndexPage)} />
          <Route exact path="/survey-deliveries/new" component={withAuth(SurveyDeliveryEditPage)} />
          <Route exact path="/survey-deliveries/:id(\d+)" component={withAuth(SurveyDeliveryDetailPage)} />
          <Route exact path="/survey-deliveries/:id(\d+)/edit" component={withAuth(SurveyDeliveryEditPage)} />
          <Route exact path="/survey-results" component={withAuth(SurveyResultIndexPage)} />
          <Route exact path="/survey-results/:id(\d+)" component={withAuth(SurveyResultDetailPage)} />
          <Route exact path="/counselings" component={withAuth(CounselingIndexPage)} />
          <Route exact path="/counselings/new" component={withAuth(CounselingCreatePage)} />
          <Route exact path="/counselings/bulk-new" component={withAuth(CounselingBulkCreatePage)} />
          <Route exact path="/counselings/:id(\d+)" component={withAuth(CounselingDetailPage)} />
          <Route exact path="/counselings/:id(\d+)/edit" component={withAuth(CounselingEditPage)} />
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  </AppProvider>,
  document.getElementById('app'),
);
