import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Box,
  Typography,
  Paper,
  CircularProgress,
  Link,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import Encoding from 'encoding-japanese';
import { SideMenuLayout } from '../../layouts';
import { LinkForward } from '../../components/common/LinkForward';
import { withAuth, AuthedComponentProps } from '../../auth/Authenticated';
import { SurveyDeliveryResponse, SurveyDeliveryApi, SurveyResultApi } from '../../generated';
import { usePrivateApi } from '../../api/useApi';

type Props = AuthedComponentProps & RouteComponentProps<{ id: string }>;

const Content: React.FunctionComponent<Props> = (
  { session, match }: Props,
) => {
  const [loading, setLoading] = useState(false);
  const [surveyDelivery, setSurveyDelivery] = useState<SurveyDeliveryResponse>();
  const { api } = usePrivateApi(SurveyDeliveryApi);
  const { api: surveyResultApi } = usePrivateApi(SurveyResultApi);
  const id = Number(match.params.id);

  useEffect(() => {
    setLoading(true);
    const request = api.surveyDeliveriesIdGet(id);
    request.then(res => {
      setSurveyDelivery(res.data);
    }).finally(() => {
      setLoading(false);
    });
  }, [match, session]);

  const download = () => {
    surveyResultApi.surveyResultsCsvGet(id).then(res => {
      const original = (new TextEncoder()).encode(res.data);
      const encoded = new Uint8Array(Encoding.convert(original, 'SJIS', 'UTF8'));
      const blob = new Blob([encoded], { type: 'text/csv' });
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = `survey_delivery_${id}_result.csv`;
      a.click();
    });
  };

  if (loading) return <CircularProgress />;
  if (!loading && surveyDelivery) {
    return (
      <>
        <Typography variant="h4" component="h1">
          アンケート配信詳細ページ
        </Typography>
        <Paper>
          <Box p={2}>
            <Typography variant="h6" component="h3">アンケートタイトル</Typography>
            <Typography component="p">{surveyDelivery.surveyTitle}</Typography>
          </Box>
          <Box p={2}>
            <Typography variant="h6" component="h3">アンケート配信予定日時</Typography>
            <Typography component="p">
              {format(new Date(surveyDelivery.deliveryTimestamp), 'PPP(E) p', { locale: ja })}
            </Typography>
          </Box>
          <Box p={2}>
            <Typography variant="h6" component="h3">アンケート有効状態</Typography>
            <Typography component="p">{surveyDelivery.deliveryStatus ? '有効' : '無効'}</Typography>
          </Box>
          <Box p={2}>
            <Typography variant="h6" component="h3">アンケートURL</Typography>
            <Typography component="p">
              <Link href={`${process.env.REACT_APP_EMPLOYEE_URL}surveys/${surveyDelivery.uuid}`} target="blank">
                {`${process.env.REACT_APP_EMPLOYEE_URL}surveys/${surveyDelivery.uuid}`}
              </Link>
            </Typography>
          </Box>
        </Paper>
        <Box display="flex" mt={2}>
          <Button
            variant="contained"
            onClick={download}
          >
            <GetAppIcon />
            回答結果ダウンロード
          </Button>
          <Box flexGrow={1} />
          <Box>
            <Button
              variant="contained"
              color="primary"
              component={LinkForward(`/survey-deliveries/${match.params.id}/edit`)}
            >
              <EditIcon />
              編集する
            </Button>
          </Box>
        </Box>
      </>
    );
  }

  return <p>404 not found</p>;
};

export const SurveyDeliveryDetailPage = SideMenuLayout(withAuth(withRouter(Content)));
