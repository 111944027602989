import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import {
  Grid,
  Button,
  Box,
  TextField,
  FormHelperText,
  CircularProgress,
  Link,
} from '@material-ui/core';
import firebase from 'firebase';
import { useDispatch } from 'react-redux';
import useForm from 'react-hook-form';
import { MainLayout } from '../../layouts';
import { SessionState } from '../../redux/Session/SessionState';
import { updateSession } from '../../redux/Session/SessionAction';
import { usePublicApi } from '../../api/useApi';
import { AccountAuthRequest, AccountApi } from '../../generated';
import { Toast } from '../../components/common/Toast';

const isEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const Content: React.SFC = () => {
  const {
    register, handleSubmit, errors, getValues,
  } = useForm();
  const { history } = useReactRouter();
  const { api } = usePublicApi(AccountApi);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const dispatch = useDispatch();

  const emailRef = register({
    required: '必須項目です',
    validate: v => isEmail(v) || '正しいEメールアドレスではありません',
  });

  const passwordRef = register({
    required: '必須項目です',
    minLength: {
      value: 8,
      message: '8文字以上必要です',
    },
  });

  const login = async () => {
    setLoading(true);
    setMessage(null);
    const values = getValues();
    const { email, password } = values;
    if (typeof email !== 'string' || typeof password !== 'string') {
      throw new Error('email or password is undefined');
    }

    // FirebaseにID/Passを投げてTokenを受け取り
    try {
      const res = await firebase.auth()
        .signInWithEmailAndPassword(email, password);
      const { user } = res;
      if (!user) {
        throw new Error('user is not found');
      }

      const token = await user.getIdToken();

      const param: AccountAuthRequest = {
        email,
        token,
      };
      const response = await api.accountsAuthPost(param);
      const account = response.data;

      const session: SessionState = {
        principal: { name: account.name, id: String(account.userId) },
        isVerified: user.emailVerified,
        token,
        expireAt: new Date(), // TODO: set expiration time
      };
      dispatch(updateSession(session));
      history.push('/');
    } catch (_err) {
      setMessage('ログインできませんでした');
    }
    setLoading(false);
  };

  const onSubmit = () => {
    login();
  };

  return (
    <Grid
      container
      alignContent="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid
        item
        style={{
          background: 'white',
          borderRadius: '5px',
          width: '500px',
          marginBottom: '10px',
          padding: '20px',
        }}
      >
        <Box p={2}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box p={1}>
              <TextField
                type="text"
                name="email"
                label="Email"
                fullWidth
                inputRef={emailRef}
              />
              {
                errors.email && (
                  <FormHelperText error>
                    {errors.email.message}
                  </FormHelperText>
                )
              }
            </Box>
            <Box p={1}>
              <TextField
                type="password"
                name="password"
                label="Password"
                fullWidth
                inputRef={passwordRef}
              />
              {
                errors.password && (
                  <FormHelperText error>
                    {errors.password.message}
                  </FormHelperText>
                )
              }
            </Box>
            <Box p={1}>
              <Button
                color="primary"
                type="submit"
                fullWidth
                disabled={loading}
                style={{ lineHeight: '36px' }}
              >
                {loading && <CircularProgress size={30} />}
                ログイン
              </Button>
            </Box>
          </form>
          <Box style={{ textAlign: 'center' }}>
            <Box m={1}>
              <Link to="/auth/register" component={RouterLink}>新規登録アカウントを登録する</Link>
            </Box>
            <Box m={1}>
              <Link to="/auth/password-reset" component={RouterLink}>パスワードを忘れた方はこちら</Link>
            </Box>
          </Box>
        </Box>

        <Toast
          duration={3000}
          message={message}
        />
      </Grid>
    </Grid>
  );
};


export const LoginPage = MainLayout(Content);
