import React from 'react';
import { Redirect } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { sessionSelector } from '../foundation/Store';
import { LoggedIn } from '../redux/Session/SessionState';

export interface AuthedComponentProps {
  session: LoggedIn;
}

/**
 * 認証済みのときだけComponentを返すHOC
 * 認証済みであればLoggedInをComponentに渡し、そうでなければLoginページにリダイレクトする
 * @param Component ラップしたいコンポーネント
 */
export const withAuth = (Component: React.ComponentType<AuthedComponentProps>) => () => {
  const session = useSelector(sessionSelector);
  const { location } = useReactRouter();

  if (session === 'guest') {
    return <Redirect to="/auth/signin" />;
  }

  // 未認証ユーザは未認証ページに飛ばす
  if (!session.isVerified && !/^\/auth\/not-verified/.test(location.pathname)) {
    return <Redirect to="/auth/not-verified" />;
  }

  return (<Component session={session} />);
};

export const withoutAuth = (Component: React.ComponentType) => () => {
  const session = useSelector(sessionSelector);

  if (session !== 'guest') {
    return <Redirect to="/" />;
  }
  return <Component />;
};
