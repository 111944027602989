import React from 'react';
import {
  Paper, Table, TableHead, TableRow, TableCell, TableBody, Theme, makeStyles, createStyles,
} from '@material-ui/core';
import useReactRouter from 'use-react-router';
import { red } from '@material-ui/core/colors';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { SurveyResultResponse } from '../generated';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  row: {},
  alertRow: {
    backgroundColor: red[50],
  },
}));
interface IProps {
  surveyResults?: SurveyResultResponse[];
}

const Content = ({ surveyResults }: IProps) => {
  const classes = useStyles();
  const { history } = useReactRouter();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>アンケート名</TableCell>
            <TableCell>従業員名</TableCell>
            <TableCell>Emailアドレス</TableCell>
            <TableCell>回答開始日時</TableCell>
            <TableCell>回答完了日時</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {surveyResults ? surveyResults.map(r => (
            <TableRow key={r.id} hover onClick={() => history.push(`/survey-results/${r.id}`)} className={r.alerted ? classes.alertRow : classes.row}>
              <TableCell>{r.alerted ? <ErrorOutlineIcon color="error" /> : null}</TableCell>
              <TableCell component="th" scope="row">{r.title}</TableCell>
              <TableCell>
                {r.employeeName}
                {r.alerted}
              </TableCell>
              <TableCell>{r.employeeEmail}</TableCell>
              <TableCell>{format(new Date(r.startTimestamp), 'PPP(E) p', { locale: ja })}</TableCell>
              <TableCell>{r.finishTimestamp && format(new Date(r.finishTimestamp), 'PPP(E) p', { locale: ja })}</TableCell>
            </TableRow>
          )) : <></>}
        </TableBody>
      </Table>
    </Paper>

  );
};

export const SurveyResultList = Content;
