import React from 'react';
import {
  Paper, Theme, makeStyles, createStyles, Typography,
} from '@material-ui/core';
import { CurrentContractResponse } from '../generated';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto',
    padding: '12px',
  },
}));

interface IProps {
  contractInfo?: CurrentContractResponse;
}

const Content = ({ contractInfo }: IProps) => {
  const classes = useStyles({});

  return (
    contractInfo
      ? (
        <Paper className={classes.root}>
          <Typography
            component="p"
            color={(contractInfo.contractedCounselingsCount > contractInfo.usedCounselingsCount) ? 'initial' : 'error'}
          >
            <strong>契約面談数：</strong>
            {contractInfo.contractedCounselingsCount}
            {'　 '}
            <strong>実施済み面談数：</strong>
            {contractInfo.usedCounselingsCount}
          </Typography>
        </Paper>
      )
      : (<Paper />)
  );
};

export const CurrentContractInfo = Content;
