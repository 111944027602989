import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Typography,
  FormLabel,
  Paper,
  FormControlLabel,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import useForm from 'react-hook-form';
import useReactRouter from 'use-react-router';
import SaveIcon from '@material-ui/icons/Save';
import { format, startOfTomorrow } from 'date-fns';
import { SideMenuLayout } from '../../layouts';
import { SurveyDeliveryRequest, SurveyDeliveryApi } from '../../generated';
import { withAuth, AuthedComponentProps } from '../../auth/Authenticated';
import { usePrivateApi } from '../../api/useApi';
import { Toast } from '../../components/common/Toast';

const Content: React.FunctionComponent<AuthedComponentProps> = (
  { session }: AuthedComponentProps,
) => {
  const { match, history } = useReactRouter<{ id: string }>();
  const isEditPage = !match.path.includes('new');
  const {
    register, handleSubmit, errors, getValues, setValue,
  } = useForm();
  const { api, loading, error } = usePrivateApi(SurveyDeliveryApi);
  const id = Number(match.params.id);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (isEditPage) {
      const request = api.surveyDeliveriesIdGet(id);
      request.then(res => {
        const surveyDelivery = res.data;
        const { deliveryTimestamp } = surveyDelivery;
        setValue('deliveryTimestamp', format(new Date(deliveryTimestamp), "yyyy-MM-dd'T'HH:mm"));
        setValue('deliveryStatus', surveyDelivery.deliveryStatus);
        setVisible(true);
      });
    } else {
      setValue('deliveryTimestamp', format(startOfTomorrow(), "yyyy-MM-dd'T'hh:mm"));
      setVisible(true);
    }
  }, [match, session]);

  const onSubmit = () => {
    const values = getValues();
    const newSurveyDelivery: SurveyDeliveryRequest = {
      deliveryTimestamp: new Date(values.deliveryTimestamp as Date),
      deliveryStatus: values.deliveryStatus as boolean,
    };

    const req = isEditPage
      ? api.surveyDeliveriesIdPut(id, newSurveyDelivery)
      : api.surveyDeliveriesPost(newSurveyDelivery);
    req.then(_res => {
      history.push('/survey-deliveries');
    });
  };

  const errorMessage = () => {
    if (error) {
      return 'アンケート配信の保存に失敗しました';
    }
    return null;
  };

  if (loading && !visible) return <CircularProgress />;

  return (
    <>
      <Typography variant="h4" component="h1">
        アンケート配信詳細ページ
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          <Box p={2}>
            <FormLabel component="legend">アンケート配信予定日時</FormLabel>
            <input
              type="datetime-local"
              name="deliveryTimestamp"
              min="2000-01-01T00:00"
              max="3000-01-01T00:00"
              ref={register({ required: true })}
            />
            {
              errors.deliveryTimestamp && (
                <FormHelperText error>
                  {errors.deliveryTimestamp.message}
                </FormHelperText>
              )
            }
          </Box>
          <Box p={2}>
            <FormControlLabel
              control={(
                <input
                  type="checkbox"
                  name="deliveryStatus"
                  ref={register}
                />
              )}
              label="アンケート有効状態"
            />
            {
              errors.deliveryStatus && (
                <FormHelperText error>
                  {errors.deliveryStatus.message}
                </FormHelperText>
              )
            }
          </Box>
        </Paper>
        <Box display="flex" mt={2}>
          <Box flexGrow={1} />
          <Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : <SaveIcon />}
              保存する
            </Button>
          </Box>
        </Box>
      </form>
      <Toast
        message={errorMessage()}
        duration={3000}
      />
    </>
  );
};

export const SurveyDeliveryEditPage = SideMenuLayout(withAuth(Content));
