import { Action } from 'redux';
import actionCreatorFactory from 'typescript-fsa';

export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';

interface Increment extends Action {
  type: typeof INCREMENT;
}

interface Decrement extends Action {
  type: typeof DECREMENT;
}

const actionCreator = actionCreatorFactory();
export const increment = actionCreator(INCREMENT);
export const decrement = actionCreator(DECREMENT);

export type CountAction = Increment | Decrement;
