import React from 'react';
import { format } from 'date-fns';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AlertsResponse } from '../../generated';

const useStyles = makeStyles(() => createStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  cell: {
    overflowWrap: 'break-word',
    maxWidth: '200px',
  },
}));

interface IProps {
  alertsResponse?: AlertsResponse;
}


const Content = ({ alertsResponse }: IProps) => {
  const classes = useStyles({});

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>従業員名</TableCell>
            <TableCell>アラート原因</TableCell>
            <TableCell>発生日時</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {alertsResponse ? alertsResponse.alerts.map(a => (
            <TableRow key={a.id}>
              <TableCell>
                <Link to={`/employees/${a.employeeId}`}>
                  {a.employeeName}
                </Link>
              </TableCell>
              <TableCell className={classes.cell}>{a.labels.filter((x, i, self) => self.indexOf(x) === i).join('、')}</TableCell>

              <TableCell>{a.createdAt && format(new Date(a.createdAt), 'yyyy年MM月dd日')}</TableCell>
            </TableRow>
          )) : <></>}
        </TableBody>
      </Table>
    </Paper>
  );
};

export const AlertList = Content;
