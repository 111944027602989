import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  CircularProgress,
  Typography,
  Button,
} from '@material-ui/core';
import { LinkForward } from '../../components/common/LinkForward';
import { SideMenuLayout } from '../../layouts';
import { SurveyDeliveryList, SurveyDelivery } from '../../components/SurveyDeliveryList';
import { withAuth, AuthedComponentProps } from '../../auth/Authenticated';
import { usePrivateApi } from '../../api/useApi';
import { SurveyDeliveryApi } from '../../generated';

const Content: React.FunctionComponent<AuthedComponentProps> = (
  { session }: AuthedComponentProps,
) => {
  const [loading, setLoading] = useState(false);
  const [surveyDeliveries, setSurveyDeliveries] = useState<SurveyDelivery[]>();
  const { api } = usePrivateApi(SurveyDeliveryApi);

  useEffect(() => {
    setLoading(true);
    const req = api.surveyDeliveriesGet();
    req.then(res => {
      const items = res.data;
      const newDeliveries: SurveyDelivery[] = items.map(i => ({
        surveyTitle: i.surveyTitle,
        surveyDeliveryId: String(i.id),
        surveyDeliveryDate: i.deliveryTimestamp,
      }));
      setSurveyDeliveries(newDeliveries);
    }).catch(_ => {
    }).finally(() => {
      setLoading(false);
    });
  }, [session]);

  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" component="h1">
            アンケート配信一覧ページ
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            component={LinkForward('/survey-deliveries/new')}
          >
            アンケート配信追加

          </Button>
        </Box>
      </Box>
      {
        loading
          ? (
            <Grid container justify="center">
              <CircularProgress />
            </Grid>
          )
          : <SurveyDeliveryList surveyDeliveries={surveyDeliveries} />
      }
    </>
  );
};

export const SurveyDeliveryIndexPage = SideMenuLayout(withAuth(Content));
