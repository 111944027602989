import React from 'react';
import { Link } from 'react-router-dom';
import { MainLayout } from '../layouts';

const Content = () => (
  <>
    <h2>404 Not Found</h2>
    <Link to="/">トップページへ戻る</Link>
  </>
);

export const NotFoundPage = MainLayout(Content);
