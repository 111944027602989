import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  Button,
  Box,
  Typography,
  Checkbox,
  Paper,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import useReactRouter from 'use-react-router';
import PlusIcon from '@material-ui/icons/PlaylistAdd';
import { SideMenuLayout } from '../../layouts';
import {
  BulkCreateCounselingRequest,
  EmployeeApi,
  AlertDetailResponse, AlertApi,
  CounselingResponse, CounselingApi,
  CurrentContractResponse, ContractApi,
  SurveyDeliveryApi,
} from '../../generated';
import { withAuth, AuthedComponentProps } from '../../auth/Authenticated';
import { usePrivateApi } from '../../api/useApi';
import { Employee } from '../../components/employee/EmployeeList';
import { CurrentContractInfo } from '../../components/CurrentContractInfo';

const Content: React.FunctionComponent<AuthedComponentProps> = (
  { session }: AuthedComponentProps,
) => {
  const { history } = useReactRouter();
  const [loading, setLoading] = useState(false);
  const { api: counselingApi } = usePrivateApi(CounselingApi);
  const { api: alertApi } = usePrivateApi(AlertApi);
  const { api: employeeApi } = usePrivateApi(EmployeeApi);
  const { api: surveyDeliveryApi } = usePrivateApi(SurveyDeliveryApi);
  const { api: contractApi } = usePrivateApi(ContractApi);
  const [alerts, setAlerts] = useState<AlertDetailResponse[]>();
  const [lastestCounselings, setLastestCounselings] = useState<CounselingResponse[]|undefined>();
  const [contractInfo, setContractInfo] = useState<CurrentContractResponse>();
  const [openDialog, setOpenDialog] = useState(false);
  const [filterText, setFilterText] = useState('');
  const handleFilterTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const [checkedEmployees, setCheckedEmployees] = useState<Employee[]>([]);
  const [leftEmployees, setLeftEmployees] = useState<Employee[]>([]);
  const [rightEmployees, setRightEmployees] = useState<Employee[]>([]);

  function not(a: Employee[], b: Employee[]) {
    return a.filter(value => b.indexOf(value) === -1);
  }

  function intersection(a: Employee[], b: Employee[]) {
    return a.filter(value => b.indexOf(value) !== -1);
  }
  const leftCheckedEmployees = intersection(checkedEmployees, leftEmployees);
  const rightCheckedEmployees = intersection(checkedEmployees, rightEmployees);

  const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
      margin: 'auto',
    },
    listPaper: {
      width: 300,
      height: 400,
      overflow: 'auto',
    },
    controlPanel: {
      height: 300,
      width: 150,
      overflow: 'auto',
    },
    listButton: {
      margin: theme.spacing(0.5, 0),
      backgroundColor: '#fff',
    },
    alerterd: {
      color: '#AF1831',
    },
    tooltip: {
      fontSize: theme.typography.pxToRem(14),
    },
  }));
  const classes = useStyles();

  const submit = () => {
    const newCounselings: BulkCreateCounselingRequest = {
      employeeIds: rightEmployees.map(e => e.id),
    };
    const req = counselingApi.counselingsBulkCreatePost(newCounselings);
    req.then(() => {
      history.push('/counselings');
    });
  };

  function showableEmployees() {
    return leftEmployees.filter(employee => employee.employeeName.indexOf(filterText) !== -1).sort(
      (a, b) => ((a.employeeName > b.employeeName) ? 1 : -1),
    );
  }

  function isAlert(employee: Employee): boolean {
    if (alerts === undefined) { return false; }
    return (alerts.map(alert => alert.employeeId).indexOf(employee.id) !== -1);
  }

  function isReserved(employee: Employee): boolean {
    if (lastestCounselings === undefined) { return false; }
    return (lastestCounselings.map(c => c.employeeId).indexOf(employee.id) !== -1);
  }

  function statusText(employee: Employee): string {
    const status: Array<string> = [];
    if (isAlert(employee)) { status.push('アラート有り'); }
    if (isReserved(employee)) { status.push('面談調整済み'); }
    if (status === []) {
      return '異常なし';
    }
    return status.join('、');
  }

  useEffect(() => {
    setLoading(true);
    const employeeReq = employeeApi.employeesGet().then(res => {
      setLeftEmployees(res.data.items);
    });
    const alertReq = alertApi.alertsGet().then(res => {
      setAlerts(res.data.alerts);
    });
    contractApi.currentContractGet().then(res => {
      setContractInfo(res.data);
    });
    let counselingReq;
    const deliveryReq = surveyDeliveryApi.surveyDeliveriesGet().then(res => {
      const filteredDeliveries = res.data.filter(el => el.deliveryTimestamp && (new Date(el.deliveryTimestamp) < new Date()));
      if (filteredDeliveries.length > 0) {
        const lastDeliveryAt = filteredDeliveries.sort(
          (a, b) => ((a.deliveryTimestamp > b.deliveryTimestamp) ? 1 : -1),
        )[0].deliveryTimestamp;
        counselingReq = counselingApi.counselingsGet().then(counselingRes => {
          const latestDeliverysCounselings = counselingRes.data.filter(c => ((
            c.status !== 'counseled' && (c.status === 'reservation' || (c.reservationTimestamp && (c.reservationTimestamp > lastDeliveryAt))))));
          setLastestCounselings(latestDeliverysCounselings);
        });
      }
    });

    // contractInfoのpromiseを監視しないのは意図的。このリクエストは契約情報が入ってないと500を返すため
    Promise.all([employeeReq, alertReq, deliveryReq, counselingReq]).finally(() => {
      setLoading(false);
    });
  }, [session]);

  const handleToggle = (employee: Employee) => () => {
    if (isReserved(employee)) { return; }
    const currentIndex = checkedEmployees.indexOf(employee);
    const newCheckedEmployees = [...checkedEmployees];

    if (currentIndex === -1) {
      newCheckedEmployees.push(employee);
    } else {
      newCheckedEmployees.splice(currentIndex, 1);
    }

    setCheckedEmployees(newCheckedEmployees);
  };

  const handleCheckedRight = () => {
    setRightEmployees(rightEmployees.concat(leftCheckedEmployees));
    setLeftEmployees(not(leftEmployees, leftCheckedEmployees));
    setCheckedEmployees(not(checkedEmployees, leftCheckedEmployees));
  };

  const handleCheckedLeft = () => {
    setLeftEmployees(leftEmployees.concat(rightCheckedEmployees));
    setRightEmployees(not(rightEmployees, rightCheckedEmployees));
    setCheckedEmployees(not(checkedEmployees, rightCheckedEmployees));
  };

  const handleAllLeft = () => {
    setLeftEmployees(leftEmployees.concat(rightEmployees));
    setRightEmployees([]);
  };

  const handleCheckedAllAletedEmployee = () => {
    const alertedEmployeeLeft = leftEmployees.filter(e => isAlert(e) && !isReserved(e));
    setCheckedEmployees(alertedEmployeeLeft);
  };

  const handleClickOpenDialog = () => {
    if (rightEmployees.length === 0) { return; }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const customList = (employees: Employee[]) => (
    <Paper className={classes.listPaper}>
      <List dense component="div" role="list">
        {employees.map((employee: Employee) => {
          const labelId = `transfer-list-item-${employee}-label`;
          return (
            <ListItem key={employee.id} role="listitem" button onClick={handleToggle(employee)}>
              <ListItemIcon>
                <Checkbox
                  checked={checkedEmployees.indexOf(employee) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                  disabled={isReserved(employee)}
                />
              </ListItemIcon>
              <Tooltip title={(
                <React.Fragment>
                  <div className={classes.tooltip}>
                    <Typography color="inherit">{employee.employeeName}</Typography>
                    <p>
Email：
                      {employee.email}
                    </p>
                    <p>
従業員番号：
                      {employee.employeeNumber}
                    </p>
                    <p>
ステータス：
                      {statusText(employee)}
                    </p>
                  </div>
                </React.Fragment>
)}
              >
                <ListItemText id={labelId} primary={employee.employeeName} className={isAlert(employee) ? classes.alerterd : ''} />
              </Tooltip>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  if (loading || lastestCounselings === undefined) return <CircularProgress />;
  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" component="h1">
          面談調整作成
          </Typography>
        </Box>
        <Box>
          <CurrentContractInfo contractInfo={contractInfo} />
        </Box>
      </Box>

      <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Paper>
                <TextField label="氏名絞り込み" value={filterText} onChange={handleFilterTextChange} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {customList(showableEmployees())}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.listButton}
              onClick={handleCheckedRight}
              disabled={leftCheckedEmployees.length === 0}
              aria-label="move selected right"
            >
            &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.listButton}
              onClick={handleCheckedLeft}
              disabled={rightCheckedEmployees.length === 0}
              aria-label="move selected left"
            >
            &lt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.listButton}
              onClick={handleCheckedAllAletedEmployee}
              aria-label="move all alerts"
            >
          アラート一括選択
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.listButton}
              onClick={handleAllLeft}
              disabled={rightEmployees.length === 0}
              aria-label="move all left"
            >
          リセット
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          {customList(rightEmployees)}
        </Grid>
      </Grid>
      <Box display="flex" mt={2}>
        <Box flexGrow={1} />
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpenDialog}
            disabled={rightEmployees.length === 0}
          >
            <PlusIcon />
            面談を設定する
          </Button>
        </Box>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">面談設定の確認</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              {' '}
              {rightEmployees.length}
件の面談を新たに設定しようとしています。
            </p>
            <p>
              {contractInfo && `現在、実施済みの面談${contractInfo.usedCounselingsCount}件と
              予約済みの面談${contractInfo.reservedCounselingsCount}件、
              合わせて${contractInfo.usedCounselingsCount + contractInfo.reservedCounselingsCount}件を利用中です。
              契約している面談数は${contractInfo.contractedCounselingsCount}件までとなっています。
              `}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleCloseDialog && submit} color="primary" autoFocus>
            設定する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export const CounselingBulkCreatePage = SideMenuLayout(withAuth(Content));
