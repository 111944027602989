import { combineReducers, createStore, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { useSelector } from 'react-redux';
import { CounterState } from '../redux/counter/CounterState';
import { counterReducer } from '../redux/counter/CounterReducer';
import { SessionState } from '../redux/Session/SessionState';
import { sessionReducer } from '../redux/Session/SessionReducer';

// for redux-devtools
declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export interface AppState {
  counter: CounterState;
  session: SessionState;
}

const rootReducer = combineReducers<AppState>({
  counter: counterReducer,
  session: sessionReducer,
});

const persistConfig = {
  key: 'root', // storage格納時のKey名
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const counterSelector = (state: AppState): CounterState => state.counter;
export const sessionSelector = (state: AppState): SessionState => state.session;
export const useSession = () => useSelector(sessionSelector);

/**
 * Storeの初期化関数
 *
 * @param callback 初期化完了時に実行されるコールバック関数
 */
export const configureStore = (callback?: () => any | undefined) => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(),
  );
  const persistor = persistStore(store, {}, callback);

  return { store, persistor };
};
