/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import {
  Grid,
  Button,
  Box,
  TextField,
  FormHelperText,
  CircularProgress,
  Snackbar,
  Checkbox,
} from '@material-ui/core';
import firebase from 'firebase';
import { useDispatch } from 'react-redux';
import useForm from 'react-hook-form';
import { MainLayout } from '../../layouts';
import { SessionState } from '../../redux/Session/SessionState';
import { updateSession } from '../../redux/Session/SessionAction';
import { usePublicApi } from '../../api/useApi';
import { AccountCreateRequest, AccountApi } from '../../generated';

// TODO: 一箇所にまとめる
const isEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const Content: React.SFC = () => {
  const {
    register, handleSubmit, errors, getValues, watch,
  } = useForm();
  const { history } = useReactRouter();
  const { api } = usePublicApi(AccountApi);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const dispatch = useDispatch();

  const organizationNameRef = register({
    required: '必須項目です',
  });

  const nameRef = register({
    required: '必須項目です',
  });

  const emailRef = register({
    required: '必須項目です',
    validate: v => isEmail(v) || '正しいEメールアドレスではありません',
  });

  const passwordRef = register({
    required: '必須項目です',
    minLength: {
      value: 8,
      message: '8文字以上必要です',
    },
  });

  const passwordConfirmRef = register({
    required: '必須項目です',
    minLength: {
      value: 8,
      message: '8文字以上必要です',
    },
    validate: v => v === watch('password') || 'パスワードと確認用パスワードが一致しません',
  });

  const agreementRef = register({
    required: '利用規約に同意する必要があります',
  });

  const login = async () => {
    setLoading(true);
    const values = getValues();
    const {
      name, email, password, organization_name,
    } = values;
    if (typeof name !== 'string' || typeof email !== 'string' || typeof password !== 'string' || typeof organization_name !== 'string') {
      throw new Error('Unexpected Type: email or password or organization is incorrect.');
    } else {
      const param: AccountCreateRequest = {
        organizationName: organization_name,
        name,
        email,
        password,
      };
      const response = await api.accountsCreatePost(param);
      const account = response.data;

      try {
        // 最初にfirebaseにid/passを投げてユーザを作成する
        const res: firebase.auth.UserCredential = await firebase.auth()
          .signInWithCustomToken(account.token);
        const { user } = res;
        if (user) {
          const token = await user.getIdToken();

          // 確認メールを送信
          await user.sendEmailVerification({ url: `${window.location.origin}/` });

          const session: SessionState = {
            principal: { name, id: String(account.userId) },
            isVerified: user.emailVerified,
            token,
            expireAt: new Date(), // TODO: set expiration time
          };
          // firebaseから取得したトークンとUserIDをバックエンドのAPIに投げてアカウントを作成
          dispatch(updateSession(session));
          history.push('/');
        }
      } catch (_error) {
        setMessage('アカウント作成に失敗しました');
      } finally {
        setLoading(false);
      }
    }
  };

  const onSubmit = () => {
    login();
  };

  return (
    <Grid
      container
      spacing={2}
      alignContent="center"
      justify="center"
    >
      <Box p={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box p={1}>
            <TextField
              type="text"
              name="organization_name"
              label="組織名"
              inputRef={organizationNameRef}
            />
            {
              errors.organization_name && (
                <FormHelperText error>
                  {errors.organization_name.message}
                </FormHelperText>
              )
            }
          </Box>
          <Box p={1}>
            <TextField
              type="text"
              name="name"
              label="ご担当者様名"
              inputRef={nameRef}
            />
            {
              errors.name && (
                <FormHelperText error>
                  {errors.name.message}
                </FormHelperText>
              )
            }
          </Box>
          <Box p={1}>
            <TextField
              type="text"
              name="email"
              label="Email"
              inputRef={emailRef}
            />
            {
              errors.email && (
                <FormHelperText error>
                  {errors.email.message}
                </FormHelperText>
              )
            }
          </Box>
          <Box p={1}>
            <TextField
              type="password"
              name="password"
              label="パスワード"
              inputRef={passwordRef}
            />
            {
              errors.password && (
                <FormHelperText error>
                  {errors.password.message}
                </FormHelperText>
              )
            }
          </Box>
          <Box p={1}>
            <TextField
              type="password"
              name="passwordConfirm"
              label="パスワード(確認用)"
              inputRef={passwordConfirmRef}
            />
            {
              errors.passwordConfirm && (
                <FormHelperText error>
                  {errors.passwordConfirm.message}
                </FormHelperText>
              )
            }
          </Box>
          <Box p={1}>
            <Checkbox name="agreement" color="primary" inputRef={agreementRef} />
            <Link to="/about/terms">利用規約</Link>
            に同意する
            {
              errors.agreement && (
                <FormHelperText error>
                  {errors.agreement.message}
                </FormHelperText>
              )
            }
          </Box>
          <Box p={1}>
            <Button variant="contained" type="submit">
              {loading && <CircularProgress size={20} />}
              アカウント新規作成
            </Button>
          </Box>
        </form>
        <Box p={1}>
          <Link to="/auth/signin">go to login</Link>
        </Box>
      </Box>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={message !== null}
        autoHideDuration={3000}
        onClose={() => setMessage(null)}
        message={<span>{message}</span>}
      />
    </Grid>
  );
};


export const CreateAccountPage = MainLayout(Content);
